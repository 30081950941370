import axios from "axios";

export const base ="https://api.paysol.co.ke";


export const health = base+"/health/index.php";
export const rent = base+"/rent/index.php";
export const parking = base+"/parking/index2.php";
export const trade = base+"/trade/index2.php";

export const biller = base+"/biller/index.php";
export const authUrl = base+"/authentication/index.php";

let tokenStr = 'MTVlNmJkNDE1NWZiNDBiZTZlZTVmNjMwZDg5ZmNkMTU1NTRiOTM2MDBlY2U2ZmI2YjUwNGE4MWRmOWJjYTFkZA==';
export const execute = (params, url) => {
    return axios.post(url,params,{headers: {"Authorization": `Bearer ${tokenStr}`}})
}