<template>
    <head>

        <meta charset="utf-8" />
        <title>KENYA | Food Handler County Portal</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description" />
        <meta content="Kelvin Njuguna" name="author" />
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />


        <!-- owl.carousel css -->
        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

        <!-- App Css-->
        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

    </head>

    <body data-sidebar="dark">



    <!-- Begin page -->
    <div id="layout-wrapper">

        <SideBar/>





        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">inactive Food Handlers Certificate</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Certificates</a></li>
                                        <li class="breadcrumb-item active">Food Handlers Certificate</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="card-title text-capitalize mb-0 ">
                                            All Food handlers Certificates
                                        </h4>

                                        <div class="d-flex">
                                            <a href="bill-new.html" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize d-none">
                                                <i class="mdi mdi-plus-circle-outline  font-size-16"></i> <span class="pl-1 d-md-inline">Create A Bill</span>
                                            </a>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive ">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr class="table-dark">
                                                <th scope="col">Bill NO.</th>
                                                <th scope="col">Granted To</th>
                                                <th scope="col" class="">Duration</th>
                                                <th scope="col">Date Created</th>
                                                <th scope="col">Exp Date</th>

                                                <th>Status</th>
                                                <th scope="col" class="text-right">Amount Paid</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr  v-for="(item, index) in activeCerts" :value="item" :key="index">
                                                <td class="text-capitalize text-nowrap">{{item.billNo}}</td>
                                                <td class="text-capitalize text-nowrap">{{item.names}}</td>
                                                <td class="text-capitalize text-nowrap">{{item.duration}}</td>
                                                <td class="text-capitalize text-nowrap">{{item.startDate}}<!-- <span class="text-muted">10:56 PM</span>--></td>
                                                <td class="text-capitalize text-nowrap">{{item.endDate}} <!--<span class="text-muted">10:56 PM</span>--></td>
                                                <td><span class="badge rounded-pill bg-danger text-uppercase">{{item.status}}</span></td>
                                                <td class="text-nowrap text-right ">
                                                    <span class="fw-semibold ">KES {{item.amountPaid}}</span>
                                                </td>

                                                <td>
                                                    <div class="d-flex justify-content-end">
                                                        <button @click="viewDetails(item)" type="button" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal"><i class="bx bx-show font-size-16 align-middle me-2"></i> Details</button>
                                                        <a @click="printCertificate(item.billNo)" class="btn btn-success btn-sm waves-effect waves-light">
                                                            <i class="bx bx-printer font-size-16 align-middle me-2"></i> Print
                                                        </a>
                                                        <div class="dropdown">
                                                            <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end ">
                                                                <a @click="viewDetails(item)" class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                <a @click="printCertificate(item.billNo)" class="dropdown-item "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>


                                            </tbody>
                                            <tfoot class="table-dark">
                                            <tr>

                                                <th>
                                                    {{activeCerts.length}} Certs
                                                </th>


                                                <th class="text-nowrap" colspan="4"></th>
                                                <td colspan="">
                                                    {{activeCerts.length}} Inactive
                                                </td>

                                                <td class="text-nowrap text-right " colspan="2">
                                                    <span class="fw-semibold ">#</span>
                                                </td>
                                                <td></td>




                                            </tr>

                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Food Handler Certificate Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Certificate Prepared for:</strong><br>
                                        {{activeCert.names}}<br>
                                        {{activeCert.email}}, {{activeCert.phoneNumber}}<br>

                                        <br>
                                        Valid From {{activeCert.startDate}}
                                        <br>
                                        <span class="pr-2">Valid Till  {{activeCert.endDate}}</span>
                                        <span class="badge rounded-pill bg-success text-uppercase me-3"> {{activeCert.status}}</span>

                                    </address>
                                </div>

                                <!--  <div class="col-6 text-right">
                                      <address>
                                          <strong class="text-capitalize">Location Details</strong><br>
                                          Street/Road: Mombasa Road<br>
                                          Plot No.: 122202/1250<br>
                                          Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                          Subcounty: CBD. Ward: Mututu


                                      </address>
                                  </div>-->

                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize">Self Service Portal</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">13 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Lab Test</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3 text-capitalize">
                                                        <p class="text-muted">Dr Mary Jane at Taifa Clinic</p>
                                                        <a href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" target="_blank" type="button" class="btn btn-link waves-effect"><i class="mdi mdi-eye-outline"></i> View Result Slip</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">14 Sep 2023 12:57 PM</div>
                                                        <h5 class="mb-4">Approved</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">Mary Max</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 12:54 PM</div>
                                                        <h5 class="mb-4">Certification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">Certifcate Issued By Kiprop Tanui</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 text-sm-end d-none">
                                    <address class="mt-2 mt-sm-0">
                                        <strong>Payment Method</strong><br>
                                        <span class="fw-medium">MPESA</span><br>
                                        Phone No. 0704 549 859<br> <br>
                                        On 1 Mar 2022, 10:20 AM
                                    </address>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">{{activeCert.billNo}}</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap vertical-align-middle">
                                        <thead class="bg-light">
                                        <tr>
                                            <th>More Details</th>
                                            <th class="text-end"></th>

                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                            <td>
                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial Number</p>
                                            </td>
                                            <td class="">{{activeCert.id}}</td>

                                        </tr>

                                        <tr title="Drving a vehicle without a valid inspection certificate inspected">
                                            <td>
                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Application Date</p>

                                            </td>
                                            <td class="">{{activeCert.startDate}}</td>

                                        </tr>

                                        <tr title="Drving a vehicle without a valid inspection certificate inspected">
                                            <td>
                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Approved</p>

                                            </td>
                                            <td class="">{{activeCert.startDate}}</td>

                                        </tr>

                                        <tr title="Drving a vehicle without a valid inspection certificate inspected">
                                            <td>
                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expired</p>

                                            </td>
                                            <td class="">{{days_between(activeCert.startDate,activeCert.endDate)}} Days Ago</td>

                                        </tr>

                                        <tr title="Drving a vehicle without a valid inspection certificate inspected">
                                            <td>
                                                <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount Paid</p>

                                            </td>
                                            <td class="text-uppercase">KES {{activeCert.amountPaid}}</td>

                                        </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a @click="printCertificate(activeCert.billNo)" type="button" class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            © County
                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                Developed by County.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->


    </body>

</template>

<script>
    import SideBar from "@/components/SideBar";
    import {execute, health} from "@/api";
    export default {
        name: "ActiveCerts",
        components: {SideBar},
        data(){
            return{
                today: new Date(),
                activeCerts: [],
                activeCert:{
                    id: '',
                    billNo: '',
                    amountPaid: '',
                    names: '',
                    idNo: '',
                    phoneNumber: '',
                    email: '',
                    duration: '',
                    startDate: '',
                    endDate: '',
                    status: '',
                    dateCreated: ''
                }
            }

        },
        mounted() {
            this.getActiveCerts()
        },
        methods:{
            formatDate(date) {
                const isoString = date.toISOString().split('T')
                return isoString[0]
            },
            days_between(date1,date2) {
                const ONE_DAY = 1000 * 60 * 60 * 24;
                const date1Obj = new Date(this.formatDate(this.today));
                const date2Obj = new Date(date2);

                const differenceMs = Math.abs(date1Obj - date2Obj);
                return Math.round(differenceMs / ONE_DAY);
            },
            viewDetails(item){
                this.activeCert = item
            },

            getActiveCerts(){
                this.tests = []
                const data = new FormData();
                data.append("function", "getInActiveCerts");
                execute(data,health)
                    .then((res) =>{
                        if (res.data.success) {
                            this.activeCerts = res.data.data.inActiveCerts;
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            printCertificate(billNo){
                localStorage['params'] = JSON.stringify({
                    billNo: billNo
                })
                const routeData = this.$router.resolve({name: 'food-handler-cert'});
                window.open(routeData.href, '_blank');
            }
        }
    }
</script>

<style scoped>

</style>