<template>


<head>

    <meta charset="utf-8" />
    <title>KENYA | Food Handler County Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />

     <!-- owl.carousel css -->
     <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

     <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

  <SideBar/>

    <!-- Begin page -->
    <div id="layout-wrapper">



        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Archived Applications</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Archived Applications</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="card-title text-capitalize mb-0 ">
                                            Applications
                                        </h4>

                                        <div class="d-flex">
                                            <a href="bill-new.html" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="mdi mdi-plus-circle-outline  font-size-16"></i> <span class="pl-1 d-md-inline">Create A Bill</span>
                                            </a>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive ">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr class="table-dark">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Applicant</th>
                                                    <th scope="col">Phone</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Date Archived</th>
                                                    <th scope="col" class="">Reason for rejection</th>
                                                    <th scope="col" class="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-capitalize text-nowrap">1.</td>
                                                    <td class="text-capitalize text-nowrap">Alex Wanjala</td>
                                                    <td>0800 721 643</td>
                                                    <td class="text-nowrap">email@email.com</td>
                                                    <td class="text-capitalize text-nowrap">10 Jan 2022 at <span class="text-muted">10:56 PM</span></td>
                                                    <td>
                                                        Failed urinalysis test
                                                    </td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <button type="button" class="btn btn-info btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".decline-modal"><i class="mdi-eye mdi"></i> Application Details</button>
                                                            <a type="button" target="_blank" href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" class="btn btn-light btn-sm waves-effect waves-light text-nowrap me-3"><i class="mdi-eye mdi"></i> Result Slip</a>

                                                            <div class="dropdown d-none">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-capitalize text-nowrap">2.</td>
                                                    <td class="text-capitalize text-nowrap">Alex Wanjala</td>
                                                    <td>0800 721 643</td>
                                                    <td class="text-nowrap">email@email.com</td>
                                                    <td class="text-capitalize text-nowrap">10 Jan 2022 at <span class="text-muted">10:56 PM</span></td>
                                                    <td>
                                                        Failed urinalysis test
                                                    </td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <button type="button" class="btn btn-info btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".decline-modal"><i class="mdi-eye mdi"></i> Application Details</button>
                                                            <a type="button" target="_blank" href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" class="btn btn-light btn-sm waves-effect waves-light text-nowrap me-3"><i class="mdi-eye mdi"></i> Result Slip</a>

                                                            <div class="dropdown d-none">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-capitalize text-nowrap">3.</td>
                                                    <td class="text-capitalize text-nowrap">Alex Wanjala</td>
                                                    <td>0800 721 643</td>
                                                    <td class="text-nowrap">email@email.com</td>
                                                    <td class="text-capitalize text-nowrap">10 Jan 2022 at <span class="text-muted">10:56 PM</span></td>
                                                    <td>
                                                        Failed urinalysis test
                                                    </td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <button type="button" class="btn btn-info btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".decline-modal"><i class="mdi-eye mdi"></i> Application Details</button>
                                                            <a type="button" target="_blank" href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" class="btn btn-light btn-sm waves-effect waves-light text-nowrap me-3"><i class="mdi-eye mdi"></i> Result Slip</a>

                                                            <div class="dropdown d-none">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="text-capitalize text-nowrap">4.</td>
                                                    <td class="text-capitalize text-nowrap">Alex Wanjala</td>
                                                    <td>0800 721 643</td>
                                                    <td class="text-nowrap">email@email.com</td>
                                                    <td class="text-capitalize text-nowrap">10 Jan 2022 at <span class="text-muted">10:56 PM</span></td>
                                                    <td>
                                                        Failed urinalysis test
                                                    </td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <button type="button" class="btn btn-info btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".decline-modal"><i class="mdi-eye mdi"></i> Application Details</button>
                                                            <a type="button" target="_blank" href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" class="btn btn-light btn-sm waves-effect waves-light text-nowrap me-3"><i class="mdi-eye mdi"></i> Result Slip</a>

                                                            <div class="dropdown d-none">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot class="table-dark">
                                                <tr>
                                                    <th colspan="7">
                                                        23 Applications
                                                    </th>


                                                </tr>

                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- Test results modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Test Results</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Test Results For:</strong><br>
                                        Kelvin Njuguna AT Mombasa Road<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 12345689.<br/>
                                        <br>


                                    </address>
                                </div>
                                <div class="col-6 text-right">
                                    <address>
                                        <strong class="text-capitalize">Location Details</strong><br>
                                        Street/Road: Mombasa Road<br>
                                        Plot No.: 122202/1250<br>
                                        Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                        Subcounty: CBD. Ward: Mututu


                                    </address>
                                </div>
                            </div>

                            <div class="col-12">
                                <div class="hori-timeline mt-4">
                                    <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">
                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                    <h5 class="mb-4">Application</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted text-capitalize">Self Service Portal</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list active">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">Pending</div>
                                                    <h5 class="mb-4">Lab Test</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted">-</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">Pending</div>
                                                    <h5 class="mb-4">Application Verdict</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted">-</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">Pending ...</div>
                                                    <h5 class="mb-4">Certification</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted">-</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead class="bg-light">
                                            <tr>
                                                <th style="width: 70px;">#</th>
                                                <th>Test</th>
                                                <th class="" >Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Stool</td>
                                                <td>
                                                    <select class="form-select text-uppercase">
                                                        <option class="selected">Pass</option>
                                                        <option>Fail</option>
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Urinalysis</td>
                                                <td>
                                                    <select class="form-select text-uppercase">
                                                        <option class="selected">Pass</option>
                                                        <option>Fail</option>
                                                    </select>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>Salmonella Ag</td>
                                                <td>
                                                    <select class="form-select text-uppercase">
                                                        <option class="selected">Pass</option>
                                                        <option>Fail</option>
                                                    </select>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="col-12 mt-3">
                                <div class="mb-3">
                                    <label for="formrow-firstname-input" class="form-label">Doctor Notes</label>
                                    <textarea class="form-control" id="" cols="30" rows="3" placeholder="Start Typing ..."></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="#" target="_blank" type="button" class="btn btn-primary waves-effect waves-light w-100">
                                <i class="mdi mdi-check font-size-16 align-middle me-2"></i> Submit Changes
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- decline application modal -->
            <div class="modal fade decline-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Application Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Test Results For:</strong><br>
                                        Alex Wanjala AT Mombasa Road<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 12345689.<br/>
                                        <br>
                                        <small class="text-uppercase d-none">Application Date 1 Mar 2022, 10:20 AM</small>

                                    </address>
                                </div>
                                <div class="col-6 text-right">
                                    <address>
                                        <strong class="text-capitalize">Location Details</strong><br>
                                        Street/Road: Mombasa Road<br>
                                        Plot No.: 122202/1250<br>
                                        Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                        Subcounty: CBD. Ward: Mututu


                                    </address>
                                </div>
                                <div class="col-12">
                                    <div class="hori-timeline mt-4">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize">Self Service Portal</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">13 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Lab Test</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3 text-capitalize">
                                                        <p class="text-muted">Dr Mary Jane at Taifa Clinic</p>
                                                        <a href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" target="_blank" type="button" class="btn btn-link waves-effect"><i class="mdi mdi-eye-outline"></i> View Result Slip</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">14 Sep 2023 12:57 PM</div>
                                                        <h5 class="mb-4">Rejected</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">Mary Max</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">15 Sep 2023 12:54 PM</div>
                                                        <h5 class="mb-4">Archived</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">Certifcate Issued By Kiprop Tanui</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead class="bg-light">
                                            <tr>
                                                <th style="width: 70px;">#</th>
                                                <th>Test</th>
                                                <th class="" >Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Stool</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Urinalysis</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>Salmonella Ag</td>
                                                <td>
                                                    <span class="badge badge-soft-danger fw-bolder  text-uppercase">Fail</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="alert alert-warning" role="alert">
                                <h4 class="alert-heading">Doctor Notes</h4>
                                <p>
                                    The applicant needs a retake ...
                                </p>
                            </div>

                            <div class="col-12 mt-3">
                                <div class="alert alert-danger" role="alert">
                                    <h4 class="alert-heading">Reason For Rejection</h4>
                                    <p>
                                        Failed urinalysis test
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- delcine application modal -->

            <!-- Approve application -->
            <div class="modal fade approve-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Approve The Application</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Test Results For:</strong><br>
                                        Alex Wanjala AT Mombasa Road<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 12345689.<br/>
                                        <br>
                                        <small class="text-uppercase d-none">Application Date 1 Mar 2022, 10:20 AM</small>

                                    </address>
                                </div>
                                <div class="col-6 text-right">
                                    <address>
                                        <strong class="text-capitalize">Location Details</strong><br>
                                        Street/Road: Mombasa Road<br>
                                        Plot No.: 122202/1250<br>
                                        Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                        Subcounty: CBD. Ward: Mututu


                                    </address>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="hori-timeline">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize">Self Service Portal</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">13 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Lab Test</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3 text-capitalize">
                                                        <p class="text-muted">Dr Mary Jane at Taifa Clinic</p>
                                                        <a href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" target="_blank" type="button" class="btn btn-link waves-effect"><i class="mdi mdi-eye-outline"></i> View Result Slip</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">Pending</div>
                                                        <h5 class="mb-4">Application Verdict</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">-</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">Pending ...</div>
                                                        <h5 class="mb-4">Certification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">-</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead class="bg-light">
                                            <tr>
                                                <th style="width: 70px;">#</th>
                                                <th>Test</th>
                                                <th class="" >Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Stool</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Urinalysis</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>Salmonella Ag</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="alert alert-warning" role="alert">
                                <h4 class="alert-heading">Doctor Notes</h4>
                                <p>
                                    Looks good
                                </p>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="#" target="_blank" type="button" class="btn btn-success waves-effect waves-light w-100">
                                <i class="mdi mdi-check font-size-16 align-middle"></i> Approve Appliction
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.Approve application -->
            </div>
            <!-- invoice details -->

            <!-- certificate issueance modal -->
            <div class="modal fade certificate-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Issue Certifcate</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Test Results For:</strong><br>
                                        Andrew Kinoti AT Mombasa Road<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 12345689.<br/>
                                        <br>
                                        <small class="text-uppercase d-none">Application Date 1 Mar 2022, 10:20 AM</small>

                                    </address>
                                </div>
                                <div class="col-6 text-right">
                                    <address>
                                        <strong class="text-capitalize">Location Details</strong><br>
                                        Street/Road: Mombasa Road<br>
                                        Plot No.: 122202/1250<br>
                                        Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                        Subcounty: CBD. Ward: Mututu


                                    </address>
                                </div>
                                <div class="col-12">
                                    <div class="hori-timeline">
                                        <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">
                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Application</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted text-capitalize">Self Service Portal</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">13 Sep 2023 10:09 AM</div>
                                                        <h5 class="mb-4">Lab Test</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3 text-capitalize">
                                                        <p class="text-muted">Dr Mary Jane at Taifa Clinic</p>
                                                        <a href="PrintDocs/health-result-slip-master/health-result-slip-master/slip33.html" target="_blank" type="button" class="btn btn-link waves-effect"><i class="mdi mdi-eye-outline"></i> View Result Slip</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">14 Sep 2023 12:57 PM</div>
                                                        <h5 class="mb-4">Approved</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">Mary Max</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="item event-list active">
                                                <div>
                                                    <div class="event-date">
                                                        <div class="text-primary mb-1">Pending ...</div>
                                                        <h5 class="mb-4">Certification</h5>
                                                    </div>
                                                    <div class="event-down-icon">
                                                        <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                    </div>

                                                    <div class="mt-3 px-3">
                                                        <p class="text-muted">-</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead class="bg-light">
                                            <tr>
                                                <th style="width: 70px;">#</th>
                                                <th>Test</th>
                                                <th class="" >Results</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Stool</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Urinalysis</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>Salmonella Ag</td>
                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder  text-uppercase">Pass</span>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="alert alert-warning" role="alert">
                                <h4 class="alert-heading">Doctor Notes</h4>
                                <p>
                                    Looks good
                                </p>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Food-handler/health-permit.html" target="_blank" type="button" class="btn btn-info waves-effect waves-light w-100">
                                <i class="mdi mdi-file-check font-size-16 align-middle"></i> Process Certificate
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.Approve application -->
            </div>
            <!-- certificate issueance modal -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                             © RevenueSure
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Developed by County
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>

    </body>

</template>

<script>
    import SideBar from "@/components/SideBar";
    export default {
        name: "ReportsArchivedApplications",
        components: {SideBar}
    }
</script>

<style scoped>

</style>