<template>

<head>

    <meta charset="utf-8" />
    <title>Login | Food handler County Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body class="auth-body-bg">

    <div>
        <div class="container-fluid p-0">
            <div class="row g-0">

                <div class="col-xl-8 col-lg-8">
                    <div class="auth-full-bg pt-lg-5 p-4">
                        <div class="w-100">
                            <div class="bg-overlay"></div>
                            <div class="d-flex h-100 flex-column">

                                <div class="p-4 mt-auto">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-7">
                                            <div class="text-center">
                                                <img src="../../public/logo/county_logo_plain.png" alt="Nouveta Logo" class="img mb-3" style="width: 170px;">


                                                <h4 class="mb-0 text-white d-none"><i class="bx bxs-quote-alt-left text-success h1 align-middle me-3"></i>Right there for you</h4>
                                                <p class="font-size-16 text-white mb-0 text-capitalize">Food handler county Portal</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->

                <div class="col-xl-4 col-md-4">
                    <div class="auth-full-page-content p-md-5 p-4">
                        <div class="w-100">

                            <div class="d-flex flex-column h-100">
                                <div class="mb-4 mb-md-5">
                                    <a href="index.html" class="d-block auth-logo">
                                        <img src="../../public/logo/county_logo.png" alt="" height="18" class="auth-logo-dark">
                                        <img src="../../public/logo/county_logo.png" alt="" height="18" class="auth-logo-light">
                                    </a>
                                </div>
                                <div class="my-auto">

                                    <div>
                                        <h5 class="text-success">Welcome Back !</h5>
                                        <p class="text-muted">Sign in to continue Using the <strong>Best Birdy Billing system. </strong>
                                        </p>
                                    </div>

                                    <div class="mt-4">


                                            <div class="mb-3">
                                                <label for="username" class="form-label">Email Address</label>
                                                <input v-model="contact.email" type="text" class="form-control" id="username" placeholder="Enter your Email address">
                                            </div>

                                            <div class="mb-3">
                                                <div class="float-end">
                                                    <a href="auth-recoverpw.html" class="text-muted">Forgot password?</a>
                                                </div>
                                                <label class="form-label">Password</label>
                                                <div class="input-group auth-pass-inputgroup">
                                                    <input v-model="password" type="password" class="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon">
                                                    <button class="btn btn-light " type="button" id="password-addon"><i class="mdi mdi-eye-outline"></i></button>
                                                </div>
                                            </div>

                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="remember-check">
                                                <label class="form-check-label" for="remember-check">
                                                        Remember me
                                                    </label>
                                            </div>

                                            <div v-if="alert.success" class="alert alert-success" role="alert">
                                                {{message}}
                                            </div>

                                            <div v-if="alert.info" class="alert alert-primary" role="alert">
                                                {{message}}
                                            </div>

                                            <div v-if="alert.failed" class="alert alert-danger" role="alert">
                                                {{message}}
                                            </div>

                                            <div class="mt-3 d-grid">
                                                <button @click="login()" class="btn btn-primary waves-effect waves-light" type="submit">Log In</button>
                                            </div>

                                    </div>
                                </div>

                                <div class="mt-4 mt-md-5 text-center">
                                    <p class="mb-0">©
                                        County <strong>Food Handler County Portal</strong></p>
    </div>
    </div>


    </div>
    </div>
    </div>
    <!-- end col -->
    </div>
    <!-- end row -->
    </div>
    <!-- end container-fluid -->
    </div>

    </body>


</template>

<script>
    import {authUrl,execute} from "@/api";
    import router from "@/router";

    export default {
        name: "LoginPage",
        data() {
            return{
                message:'',
                alert:{
                    success: false,
                    info: false,
                    failed:false
                },
                contact: {
                    email: ''
                },
                password:''
            }
        },
        mounted() {
        },
        methods: {
            getWardName(wardID) {
                const nameMap = {
                    "432471235": "BARUT",
                    "432511254": "BIASHARA",
                    "432461227": "BONDE",
                    "432471231": "ELBURGON",
                    "432521259": "ELEMENTAITA",
                    "432481240": "FLAMINGO",
                    "432491241": "GITHIORO",
                    "432471236": "KAPKURES",
                    "432471234": "KAPTEMBWO",
                    "432461229": "KIAMUNYI",
                    "432481238": "KIHOTO",
                    "432461226": "KIPKELION",
                    "432451225": "LARE",
                    "432491244": "LONDON",
                    "432491243": "MAENDELEO",
                    "432521260": "MAI MAHIU",
                    "432521258": "MAI-ELLA",
                    "432531261": "MENENGAI WEST",
                    "432521257": "MIRERA",

// and so on for the rest of the wards
                };

                return nameMap[wardID] || "Village";
            },
            getName(subCountyID) {
                const nameMap = {
                    "43247": "NAIVASHA",
                    "43251": "RONGAI",
                    "43246": "GILGIL",
                    "43248": "KURESOI NORTH",
                    "43252": "KURESOI SOUTH",
                    "43249": "NAKURU WEST",
                    "43245": "NAKURU EAST",
                    "43253": "MOLO",
                    "43250": "SUBUKIA"
                };
                return nameMap[subCountyID] || "Home Town";
            },

            login(){
                this.message ="Authorizing..."
                this.alert.failed = false
                this.alert.info = true

                const data = new FormData();
                data.append("function", "login");
                data.append("email", this.contact.email);
                data.append("password", this.password);

                execute(data,)

                execute(data,authUrl)
                    .then((res) =>{
                        this.alert.info = false
                        if (res.data.success) {
                            this.message =res.data.message
                            this.alert.success = true
                            router.push('dashboard')
                        }else{
                            this.alert.failed = true
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        //alert(e.message);
                        this.message =e.message
                    });
            },
        }
    }
</script>

<style scoped>

</style>