<template>





<head>

    <meta charset="utf-8" />
    <title>Food Handler County Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


</head>

<body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <SideBar/>

        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Dashboard</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a >Dashboard</a></li>

                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>

                    <!-- quick stats -->

                    <div class="row">
                        <div class="col-xl-4 px-sm-30px">
                            <div class="card  text-white member-wedges-card index-live-stats">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <h6 class="text-white text-uppercase">Live Stats</h6>
                                        </div>
                                        <div class="col-sm-12 mb-3">
                                            <div class="live-data-card">
                                                <div class="">
                                                    <div class="me-3">
                                                        <img src="assets/images/index-icons/resume.png" alt="">
                                                    </div>
                                                    <span class="avatar-title rounded-circle bg-warning font-size-16 d-none">
                                                        <!-- <i class="mdi mdi-sticker-alert text-white"></i> -->
                                                        <img src="assets/images/index-icons/resume.png" alt="">
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="text-muted mb-1">Active Applications
                                                    </p>
                                                    <h5 class="mb-0">2,900</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 mb-3">
                                            <div class="live-data-card">
                                                <div class="">
                                                    <div class="me-3">
                                                        <img src="assets/images/index-icons/badge.png" alt="">
                                                    </div>
                                                    <span class="avatar-title rounded-circle bg-warning font-size-16 d-none">
                                                        <!-- <i class="mdi mdi-sticker-alert text-white"></i> -->
                                                        <img src="assets/images/index-icons/offline.png" alt="">
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="text-muted mb-1">Active Certificaes</p>
                                                    <h5 class="mb-0">945</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mb-3">
                                            <div class="live-data-card">
                                                <div class="">
                                                    <div class="me-3">
                                                        <img src="assets/images/index-icons/danger.png" alt="">
                                                    </div>
                                                    <span class="avatar-title rounded-circle bg-warning font-size-16 d-none">
                                                        <!-- <i class="mdi mdi-sticker-alert text-white"></i> -->
                                                        <img src="assets/images/index-icons/monitor.png" alt="">
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="text-muted mb-1">Inactive Certificates</p>
                                                    <h5 class="mb-0">945</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mb-3">
                                            <div class="live-data-card">
                                                <div class="">
                                                    <div class="me-3">
                                                        <img src="assets/images/index-icons/expired.png" alt="">
                                                    </div>
                                                    <span class="avatar-title rounded-circle bg-warning font-size-16 d-none">
                                                        <!-- <i class="mdi mdi-sticker-alert text-white"></i> -->
                                                        <img src="assets/images/index-icons/offline.png" alt="">
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="text-muted mb-1">Certificates Nearing Expiry
                                                    </p>
                                                    <h5 class="mb-0">945</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mb-3 d-non">
                                            <div class="live-data-card">
                                                <div class="">
                                                    <div class="me-3">
                                                        <img src="assets/images/index-icons/doctor-appointment.png" alt="">
                                                    </div>
                                                    <span class="avatar-title rounded-circle bg-warning font-size-16 d-none">
                                                        <!-- <i class="mdi mdi-sticker-alert text-white"></i> -->
                                                        <img src="assets/images/index-icons/doctor-appointment.png" alt="">
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="text-muted mb-1">Pending Test Appointments
                                                    </p>
                                                    <h5 class="mb-0">945</h5>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 mb-3 d-none">
                                            <div class="live-data-card">
                                                <div class="">
                                                    <div class="me-3">
                                                        <img src="assets/images/index-icons/id-card.png" alt="">
                                                    </div>
                                                    <span class="avatar-title rounded-circle bg-warning font-size-16 d-none">
                                                        <!-- <i class="mdi mdi-sticker-alert text-white"></i> -->
                                                        <img src="assets/images/index-icons/car-accident.png" alt="">
                                                    </span>
                                                </div>
                                                <div>
                                                    <p class="text-muted mb-1">NHIF STAFF</p>
                                                    <h5 class="mb-0">43</h5>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-8 px-sm-30px text-capitalize">
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="avatar-xs-2 me-3">
                                                    <span class="avatar-title rounded-circle bg-warning bg-soft text-warning  font-size-18">
                                                        <i class="mdi mdi-cash-refund h2 mb-0 pb-0 text-warning"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span>Collections Today</span>
                                                    <div class="btn-group d-none">
                                                        <button class="btn btn-link dropdown-toggle btn-sm px-0 text-left pt-0 card-month-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            Feb 2022 <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#">Feb 2022</a>
                                                            <a class="dropdown-item" href="#">Jan 2022</a>
                                                            <a class="dropdown-item" href="#">Dec 2021</a>
                                                            <a class="dropdown-item" href="#">Nov 2021</a>
                                                            <a class="dropdown-item" href="#">Oct 2021</a>
                                                            <a class="dropdown-item" href="#">Sep 2021</a>
                                                            <a class="dropdown-item" href="#">Aug 2021</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-muted mt-4">
                                                <h4>KES 2,250 <i class="bx bxs-up-arrow ms-1 text-success font-12px"></i></h4>
                                                <div class="d-flex">
                                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ms-2 text-truncate">From previous period</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="avatar-xs-2 me-3">
                                                    <span class="avatar-title rounded-circle bg-info bg-soft text-info font-size-18">
                                                        <i class="mdi mdi-cash-multiple  h2 text-success p-0 m-0"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span>Monthly collections</span>
                                                    <div class="btn-group">
                                                        <button class="btn btn-link dropdown-toggle btn-sm px-0 text-left pt-0 card-month-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            May 2022 <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#">Feb 2022</a>
                                                            <a class="dropdown-item" href="#">Jan 2022</a>
                                                            <a class="dropdown-item" href="#">Dec 2021</a>
                                                            <a class="dropdown-item" href="#">Nov 2021</a>
                                                            <a class="dropdown-item" href="#">Oct 2021</a>
                                                            <a class="dropdown-item" href="#">Sep 2021</a>
                                                            <a class="dropdown-item" href="#">Aug 2021</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-muted mt-4">
                                                <h4 class="text-uppercase">kes 21,325<i class="bx bxs-up-arrow ms-1 text-success font-12px"></i></h4>
                                                <div class="d-flex">
                                                    <span class="badge badge-soft-success font-size-12"> + 0.2% </span> <span class="ms-2 text-truncate">From previous period</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex align-items-center mb-3">
                                                <div class="avatar-xs-2 me-3">
                                                    <span class="avatar-title rounded-circle bg-success bg-soft text-success font-size-18">
                                                        <i class="mdi mdi-cash-plus  h2 mb-0 pb-0 text-whatsApp"></i>
                                                    </span>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span>Annual collections</span>
                                                    <div class="btn-group">
                                                        <button class="btn btn-link dropdown-toggle btn-sm px-0 text-left pt-0 card-month-filter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            2022 <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu">
                                                            <a class="dropdown-item" href="#">2022</a>
                                                            <a class="dropdown-item" href="#">2021</a>
                                                            <a class="dropdown-item" href="#">2020</a>
                                                            <a class="dropdown-item" href="#">2019</a>
                                                            <a class="dropdown-item" href="#">2018</a>
                                                            <a class="dropdown-item" href="#">2017</a>
                                                            <a class="dropdown-item" href="#">2016</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-muted mt-4">
                                                <h4 class="text-uppercase">kes 321,300<i class="bx bxs-up-arrow ms-1 text-success font-12px"></i></h4>

                                                <div class="d-flex">
                                                    <span class="badge badge-soft-warning font-size-12"> 0% </span> <span class="ms-2 text-truncate">From previous period</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12 px-sm-30px">
                                    <div class="card">
                                        <div class="card-header bg-white">
                                            <h4 class="card-title mb-0">Revenue Vs Applications</h4>
                                            <small class="mb-3 text-muted d-none">The last 12 Months</small>
                                        </div>
                                        <div class="card-body">
                                            <div>
                                                <div id="annual-sales" class="high-chart-cont" dir="ltr"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- end row -->
                        </div>
                    </div>

                    <!-- more charts -->
                    <div class="row d-non">
                        <div class="col-xl-6 col-lg-7 col-sm-12 px-sm-30px">
                            <div class="card calc-23">
                                <div class="card-body">
                                    <div class="clearfix">
                                        <div class="float-end d-flex ">
                                            <div class="input-group input-group-sm mx-3 d-non">
                                                <select class="form-select form-select-sm filter-by">
                                                    <option value="Violations">Expired Certificates</option>
                                                    <option value="Revenue" selected>Revenue</option>
                                                </select>
                                                <label class="input-group-text">Filter By</label>
                                            </div>

                                            <div class="input-group input-group-sm">
                                                <select class="form-select form-select-sm filter-by-month">
                                                    <option value="May" selected>May 2023</option>
                                                    <option value="Jan">Jan 2023</option>
                                                    <option value="Dec">Dec 2022</option>
                                                    <option value="Nov">Nov 2022</option>
                                                    <option value="Oct">Oct 2022</option>
                                                </select>
                                                <label class="input-group-text">Month</label>
                                            </div>


                                        </div>
                                        <h4 class="card-title mb-4 text-capitalize">Remittance Collection By County</h4>
                                    </div>


                                    <div id="Map-data" class="h-80" dir="ltr"></div>
                                </div>

                                <div class="card-body border-top px-4 pt-4">
                                    <h4 class="card-title mb-4">Inactive Certificates</h4>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <p class="text-muted">Inactive Certificates</p>
                                            <h3>945</h3>
                                            <p class="text-muted"><span class="text-success me-2"> 12% <i class="mdi mdi-arrow-up"></i> </span> Renewed This Month</p>

                                            <div class="mt-4">
                                                <a href="cert-inactive.html" class="btn btn-primary waves-effect waves-light btn-sm">Inactive Certificates <i class="mdi mdi-arrow-right ms-1"></i></a>
                                            </div>
                                        </div>
                                        <div class="col-sm-8">
                                            <div class="mt-4 mt-sm-0">
                                                <div id="radialBar-chart" data-colors='["--bs-primary"]' class="apex-charts"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end card-->
                        </div>

                        <div class="col-xl-6 col-lg-5 col-sm-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header bg-white border-bottom">
                                            <div class="float-end">
                                                <select class="form-select form-select-sm ms-2">
                                                    <option value="MA" selected>All Sub Counties</option>
                                                    <option value="FE">Westlands</option>
                                                    <option value="JA">Roysambu</option>
                                                    <option value="DE">Kibra</option>
                                                    <option value="DE">Langata</option>
                                                </select>
                                            </div>
                                            <h4 class="card-title mb-3">Certificate Applications By Stages</h4>
                                        </div>
                                        <div class="card-body">


                                            <div class="row py-2">
                                                <div class="col-lg-4 d-none">
                                                    <div class="mt-4">
                                                        <p>Total Revenue Collected</p>
                                                        <h4>KES 567,900</h4>

                                                        <p class="text-muted mb-4"> + 3,850 ( 0.2 % ) <i class="mdi mdi-arrow-up ms-1 text-success"></i></p>

                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div>
                                                                    <p class="mb-2">value Of Bills Created</p>
                                                                    <h5>KES 789,000</h5>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="mt-4">
                                                            <a href="bills-receipted.html" class="btn btn-primary btn-sm">Receipted Bills <i class="mdi mdi-arrow-right ms-1"></i></a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-8">
                                                    <div>
                                                        <div id="wallet-balance-chart" class="apex-charts"></div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-4 align-self-center">
                                                    <div>
                                                        <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2 text-warning"></i>Pending test</p>
                                                        <h5>6 <span class="text-muted font-size-14"> Applications</span></h5>
                                                    </div>

                                                    <div class="mt-4 pt-2">
                                                        <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2 text-info"></i>Pending Verdict</p>
                                                        <h5>8<span class="text-muted font-size-14"> Applications</span></h5>
                                                    </div>

                                                    <div class="mt-4 pt-2">
                                                        <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>Approved Applications</p>
                                                        <h5>25<span class="text-muted font-size-14"> Applications</span></h5>
                                                    </div>

                                                    <div class="mt-4 pt-2">
                                                        <p class="mb-2"><i class="mdi mdi-circle align-middle font-size-10 me-2 text-danger"></i>Rejected Applications</p>
                                                        <h5>56<span class="text-muted font-size-14"> Applications</span></h5>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer bg-transparent border-top">
                                            <div class="text-center">
                                                <a href="applications.html" class="btn btn-dark waves-effect waves-light"> View Applications</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-12">
                                    <div class="card">
                                        <div class="card-header bg-white border-bottom">
                                            <div class="float-end d-non">
                                                <select class="form-select form-select-sm ms-2">
                                                    <option value="MA" selected>March</option>
                                                    <option value="FE">February</option>
                                                    <option value="JA">January</option>
                                                    <option value="DE">December</option>
                                                </select>
                                            </div>
                                            <h4 class="card-title mb-3">Appointments Fullfilment by Clinics</h4>
                                        </div>
                                        <div class="card-body">

                                            <ul class="nav nav-pills bg-light rounded d-none" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link active" data-bs-toggle="tab" href="#transactions-all-tab" role="tab" aria-selected="true">Bottom 7</a>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <a class="nav-link" data-bs-toggle="tab" href="#transactions-buy-tab" role="tab" aria-selected="false" tabindex="-1">Top 7</a>
                                                </li>
                                                <li class="nav-item d-none" role="presentation">
                                                    <a class="nav-link" data-bs-toggle="tab" href="#transactions-sell-tab" role="tab" aria-selected="false" tabindex="-1">Sell</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content mt-">
                                                <div class="tab-pane active" id="transactions-all-tab" role="tabpanel">
                                                    <div class="table-responsive" data-simplebar="init" style="max-height: 330px;"><div class="simplebar-wrapper" style="margin: 0px;"><div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask"><div class="simplebar-offset" style="right: -15px; bottom: -15px;"><div class="simplebar-content-wrapper" style="height: auto; overflow: scroll;"><div class="simplebar-content" style="padding: 0px;">
                                                        <table class="table align-middle table-nowrap">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 50px;">
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Kenyatta Hospital</h5>
                                                                            <p class="text-muted mb-0 d-none">39 Appointments</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">36 Appointments</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Sipital Kuu Medical Center</h5>
                                                                            <p class="text-muted mb-0 d-none">450 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0 text-right">30 Appointments</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Ubora Hospital</h5>
                                                                            <p class="text-muted mb-0 d-none">324 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">25 Appointments</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Afya Bora Hospital</h5>
                                                                            <p class="text-muted mb-0 d-none">1,020 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">22 Appointments</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-12 text-dark opacity-50">
                                                                            <i class="mdi mdi-checkbox-blank-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">The County Lab</h5>
                                                                            <p class="text-muted mb-0 d-none">56 Appoint</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">21 Appointments</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                    </div></div></div></div><div class="simplebar-placeholder" style="width: auto; height: 473px;"></div></div><div class="simplebar-track simplebar-horizontal" style="visibility: visible;"><div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: block; width: 196px;"></div></div><div class="simplebar-track simplebar-vertical" style="visibility: visible;"><div class="simplebar-scrollbar" style="height: 230px; transform: translate3d(0px, 0px, 0px); display: block;"></div></div></div>
                                                </div>
                                                <div class="tab-pane" id="transactions-buy-tab" role="tabpanel">
                                                    <div class="table-responsive" data-simplebar="init" style="max-height: 330px;"><div class="simplebar-wrapper" style="margin: 0px;"><div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask"><div class="simplebar-offset" style="right: 0px; bottom: 0px;"><div class="simplebar-content-wrapper" style="height: auto; overflow: hidden;"><div class="simplebar-content" style="padding: 0px;">
                                                        <table class="table align-middle table-nowrap">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 50px;">
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Kenya Commercial bank</h5>
                                                                            <p class="text-muted mb-0 d-none">345 Member sacco Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">90% Compliance</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Kenya Revenue Authority</h5>
                                                                            <p class="text-muted mb-0 d-none">450 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0 text-right">90% Compliance</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-12 text-dark opacity-50">
                                                                            <i class="mdi mdi-checkbox-blank-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Kenyatta National Hospital</h5>
                                                                            <p class="text-muted mb-0 d-none">324 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">88% Compliance</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Kenya Red Cross Society </h5>
                                                                            <p class="text-muted mb-0 d-none">1,020 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">87% Compliance</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Oxfam</h5>
                                                                            <p class="text-muted mb-0 d-none">560 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">81% Compliance</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>

                                                                <tr>
                                                                    <td style="width: 50px;">
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">KCB Group </h5>
                                                                            <p class="text-muted mb-0 d-none">32 Cars</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">76% Compliance</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">United Nations</h5>
                                                                            <p class="text-muted mb-0 d-none">15 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">76% Compliance</h5>
                                                                        </div>
                                                                    </td>


                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div></div></div></div><div class="simplebar-placeholder" style="width: 0px; height: 0px;"></div></div><div class="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div></div><div class="simplebar-track simplebar-vertical" style="visibility: hidden;"><div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div></div></div>
                                                </div>

                                                <div class="tab-pane" id="transactions-sell-tab" role="tabpanel">
                                                    <div class="table-responsive" data-simplebar="init" style="max-height: 330px;"><div class="simplebar-wrapper" style="margin: 0px;"><div class="simplebar-height-auto-observer-wrapper"><div class="simplebar-height-auto-observer"></div></div><div class="simplebar-mask"><div class="simplebar-offset" style="right: 0px; bottom: 0px;"><div class="simplebar-content-wrapper" style="height: auto; overflow: hidden;"><div class="simplebar-content" style="padding: 0px;">
                                                        <table class="table align-middle table-nowrap">
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 50px;">
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Buy BTC</h5>
                                                                            <p class="text-muted mb-0 d-none">14 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">0.016 BTC</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$125.20</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Buy LTC</h5>
                                                                            <p class="text-muted mb-0 d-none">16 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">1.88 LTC</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$94.22</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Buy ETH</h5>
                                                                            <p class="text-muted mb-0 d-none">17 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">0.42 ETH</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$84.32</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Sell ETH</h5>
                                                                            <p class="text-muted mb-0 d-none">15 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">0.56 ETH</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$112.34</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-12 text-dark opacity-50">
                                                                            <i class="mdi mdi-checkbox-blank-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Sell BTC</h5>
                                                                            <p class="text-muted mb-0 d-none">18 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">0.018 BTC</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$145.80</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-success">
                                                                            <i class="bx bx-up-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Sell ETH</h5>
                                                                            <p class="text-muted mb-0 d-none">15 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">0.56 ETH</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$112.34</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>



                                                                <tr>
                                                                    <td>
                                                                        <div class="font-size-22 text-danger">
                                                                            <i class="bx bx-down-arrow-circle"></i>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div>
                                                                            <h5 class="font-size-14 mb-1">Buy BTC</h5>
                                                                            <p class="text-muted mb-0 d-none">14 Mar, 2020</p>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 mb-0">0.016 BTC</h5>
                                                                        </div>
                                                                    </td>

                                                                    <td>
                                                                        <div class="text-end">
                                                                            <h5 class="font-size-14 text-muted mb-0">$125.20</h5>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div></div></div></div><div class="simplebar-placeholder" style="width: 0px; height: 0px;"></div></div><div class="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div></div><div class="simplebar-track simplebar-vertical" style="visibility: hidden;"><div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); display: none;"></div></div></div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="card-footer bg-transparent border-top">
                                            <div class="text-center">
                                                <a href="reports-clinic.html" class="btn btn-primary waves-effect waves-light"> View More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end of more charts -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->


            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                             © Best Birdy
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay "></div>


    </body>

</template>

<script>
    import SideBar from "@/components/SideBar";
    export default {
        name: "DashboardPage",
        components: {SideBar},
        mounted() {
            this.annualSales()
            this.walletBalanceChart()
            this.map()

        },
        methods:{
            map(){
                // Initiate the chart
                var max=9000000,min=90000
                // eslint-disable-next-line no-unused-vars,no-undef
                const Mapchart=Highcharts.mapChart('Map-data', {

                    series: [
                        {
                            name:"Revenue Collection By Sub-Counties",
                            animation: {
                                duration: 1000,
                                // easing: 'easeOutBounce'
                            },
                            states: {
                                hover: {

                                    borderColor: '#cdd3d9'
                                }
                            },
                            colorByPoint: false,
                            color: '#ebedf0', // blue
                            borderWidth: 1,
                            borderColor: 'white',
                            symbol: 'circle',
                            marker: {
                                symbol: 'square'
                            },

                            "type": "map",

                            "joinBy": "id",
                            style: {
                                fontFamily: '"Poppins",sans-serif'
                            },
                            // eslint-disable-next-line no-undef
                            "mapData": NakuruCountyMapData,
                            "data": [
                                {
                                    //Langata north
                                    "id": "id0",
                                    "y": 0,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Dagoreti South
                                    "id": "id1",
                                    "y": 1,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Kibra
                                    "id": "id2",
                                    "y": 2,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Dagoreti North
                                    "id": "id3",
                                    "y": 3,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Starehe
                                    "id": "id4",
                                    "y": 4,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //westlands
                                    "id": "id5",
                                    "y": 5,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Embakasi South
                                    "id": "id6",
                                    "y": 6,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Makadara
                                    "id": "id7",
                                    "y": 7,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Embakasi west
                                    "id": "id8",
                                    "y": 8,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Embakasi Central
                                    "id": "id9",
                                    "y": 9,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //KENYA
                                    //Kamkunji
                                    "id": "id10",
                                    "y": 10,
                                    // eslint-disable-next-line no-undef
                                    value:getRandomNumber(min,max)
                                },
                                {
                                    //Tharaka Nithi
                                    //Embakasi North
                                    "id": "id11",
                                    "y": 11,
                                    // eslint-disable-next-line no-undef
                                    value:getRandomNumber(min,max)
                                },
                                {
                                    //Embu
                                    //Embakasi East
                                    "id": "id12",
                                    "y": 12,
                                    // eslint-disable-next-line no-undef
                                    value:getRandomNumber(min,max)
                                },
                                {
                                    //Machakos county
                                    //Kasarani
                                    "id": "id13",
                                    "y": 13,
                                    // eslint-disable-next-line no-undef
                                    value:getRandomNumber(min,max)
                                },
                                {
                                    //makueni county
                                    //Ruaraka
                                    "id": "id14",
                                    "y": 14,
                                    // eslint-disable-next-line no-undef
                                    value:getRandomNumber(min,max)
                                },
                                {
                                    //Mathare
                                    "id": "id15",
                                    "y": 15,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Roysambu
                                    "id": "id16",
                                    "y": 16,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Langata
                                    "id": "id17",
                                    "y": 17,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Unmatched
                                    "id": "id18",
                                    "y": 18,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Langata
                                    "id": "id19",
                                    "y": 19,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_18",
                                    "y": 20,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_31",
                                    "y": 21,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_25",
                                    "y": 22,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_23",
                                    "y": 23,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_24",
                                    "y": 24,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_30",
                                    "y": 25,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_29",
                                    "y": 26,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_28",
                                    "y": 27,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_27",
                                    "y": 28,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_26",
                                    "y": 29,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_35",
                                    "y": 30,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_36",
                                    "y": 31,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_32",
                                    "y": 32,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_46",
                                    "y": 33,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_33",
                                    "y": 34,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_34",
                                    "y": 35,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_38",
                                    "y": 36,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_37",
                                    "y": 37,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_39",
                                    "y": 38,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_40",
                                    "y": 39,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_41",
                                    "y": 40,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //KENYA
                                    "id": "County_42",
                                    "y": 41,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_45",
                                    "y": 42,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //KENYA
                                    "id": "County_43",
                                    "y": 43,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Migori
                                    "id": "County_44",
                                    "y": 44,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    "id": "County_10",
                                    "y": 45,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                },
                                {
                                    //Kitui county
                                    "id": "County_15",
                                    "y": 46,
                                    // eslint-disable-next-line no-undef
                                    value: getRandomNumber(min,max)
                                }
                            ],
                            dataLabels: {
                                enabled: true,
                                color: '#FFFFFF',
                                format: '{point.name}'
                            },
                        }
                    ],

                    tooltip: {
                        pointFormat: '<span style="font-weight:500;">{point.name} Sub County: </span>KES {point.value}',
                        shared: true
                    },

                    chart: {
                        height: 600,
                        style: {
                            fontFamily: '"Poppins",sans-serif'
                        }
                    },

                    title: {
                        text: null,
                        style: {
                            fontSize: '14px'
                        }
                    },

                    legend: {
                        title: {
                            text: 'Revenue Collection By Sub-Counties'
                        },

                    },


                    colorAxis: {
                        min: 1,
                        minColor: '#FFFFFF',
                        // eslint-disable-next-line no-undef
                        maxColor: Highcharts.getOptions().colors[0],
                        visible: true,

                        stops: [
                            [0, '#ebedf0'],
                            [0.2, '#c6e6d0'],
                            [0.4, '#7dc794'],
                            [0.6, '#46af68'],
                            [0.7, '#358e52'],
                            [1, '#215d34']
                        ]
                    },





                    mapNavigation: {
                        enabled: true,
                        buttonOptions: {
                            verticalAlign: 'bottom'
                        }
                    },
                });
            },
            annualSales(){
                // eslint-disable-next-line no-undef
                Highcharts.chart('annual-sales', {
                    chart: {
                        zoomType: 'xy',
                        style: {
                            fontFamily: '"Poppins",sans-serif'
                        }
                    },
                    title: {
                        text: '',
                        align: 'Center'
                    },

                    xAxis: [{
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                        crosshair: true
                    }],
                    yAxis: [ { // Secondary yAxis
                        title: {
                            text: 'Applications',
                            style: {
                                color: "Black"
                            }
                        },
                        labels: {

                            style: {
                                color: "Black"
                            }
                        },
                        opposite: true,
                        gridLineColor: '#c2c2c2',
                        gridLineWidth: 0,
                        min: 0,
                    },{ // Primary yAxis
                        labels: {

                            style: {
                                color: '#007647'
                            }
                        },
                        title: {
                            text: 'Revenue (KES)',
                            style: {
                                color: "#007647"
                            }
                        }
                    }],
                    tooltip: {
                        shared: true
                    },

                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Revenue',
                        type: 'column',
                        color:'#00c399',
                        yAxis: 1,
                        data: [27452000, 62882000, 72172000, 33412000, 82902000, 102842000, 14562000, 25172000, 33902000,
                            56002000, 12862000, 13212000],
                        tooltip: {
                            valuePrefix: 'KES '
                        }

                    }, {
                        name: 'Rejected Applications',
                        type: 'spline',
                        color:'#c50000',
                        data: [130, 45, 56, 74, 60, 130, 50, 100, 50,
                            260, 87, 160],
                        tooltip: {
                            valuePrefix: ''
                        },
                        // shadow: {
                        //     color: 'black', offsetX: 0, offsetY: 5, opacity: 0.2, width: 5
                        // },
                        lineWidth: 4,
                    },
                        {
                            name: 'Approved Applications',
                            type: 'spline',
                            color:'#556ee6',
                            data: [560, 160, 50, 60, 30, 234, 140, 560, 60,
                                560, 160, 160],
                            tooltip: {
                                valuePrefix: ''
                            },
                            shadow: {
                                color: 'black', offsetX: 0, offsetY: 5, opacity: 0.2, width: 5,

                            },
                            lineWidth: 4,
                        }]
                });
            },
            walletBalanceChart(){
                var walletOptions = {
                    series: [7, 8, 26,59],
                    chart: {
                        height: 302,
                        type: "radialBar"
                    },
                    plotOptions: {
                        radialBar: {
                            offsetY: 0,
                            startAngle: 0,
                            endAngle: 270,
                            hollow: {
                                margin: 5,
                                size: "25%",
                                background: "transparent",
                                image: void 0
                            },
                            track: {
                                show: !0,
                                startAngle: void 0,
                                endAngle: void 0,
                                background: "#f2f2f2",
                                strokeWidth: "100%",
                                opacity: 1,
                                margin: 10,
                                dropShadow: {
                                    enabled: !1,
                                    top: 0,
                                    left: 0,
                                    blur: 3,
                                    opacity: .8
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: !0,
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    offsetY: -10
                                },
                                value: {
                                    show: !0,
                                    fontSize: "14px",
                                    offsetY: 4,
                                    formatter: function (e) {
                                        return e + "%"
                                    }
                                },
                                total: {
                                    show: !0,
                                    label: "Total",
                                    color: "#373d3f",
                                    fontSize: "16px",
                                    fontFamily: void 0,
                                    fontWeight: 600,
                                    formatter: function (e) {
                                        return e.globals.seriesTotals.reduce(function (e, t) {
                                            return e + t
                                        }, 0) + "%"
                                    }
                                }
                            }
                        }
                    },
                    stroke: {
                        lineCap: "round"
                    },
                    colors: ["#f1b44c", "#50a5f1", "#34c38f","#f46a6a"],
                    labels: ["Pending Test", "Pending Verdict", "Approved","Rejected"],
                    legend: {
                        show: !1
                    }
                };
                // eslint-disable-next-line no-undef
                (chart = new ApexCharts(document.querySelector("#wallet-balance-chart"), walletOptions)).render();
            }

        }
    }
</script>

<style scoped>

</style>