<template>

<head>

    <meta charset="utf-8" />
    <title>KENYA | Food handler county Portal | Your contact List</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


</head>

<body data-sidebar="dark">


    <!-- Begin page -->
    <div id="layout-wrapper">

        <SideBar/>


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Users register</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item active">User's Register</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- eTransactions table -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">
                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="mb-0">Registered Users</h4>
                                        <div class="d-flex">
                                            <a href="user-registration.html" type="button" class="btn btn-primary dropdown-toggle option-selector" data-bs-toggle="offcanvas" data-bs-target="#dt__filter" aria-controls="offcanvasRight">
                                                <i class="mdi mdi-account-multiple-plus-outline  font-size-16"></i> <span class="pl-1 d-md-inline">Add User(s)</span>
                                            </a>
                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">

                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="bx  bx-trash label-icon"></i> Delete Contact(s)</button>
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-plus label-icon"></i> Compose Message</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table" id="datatable-buttons">
                                        <thead class="table-light">
                                            <tr>

                                                <th scope="col" style="width: 40px;">
                                                    <div class="the-mail-checkbox pr-4">
                                                        <label for="selectAll" class="d-none">Select All</label>
                                                        <input class="form-check-input mt-0 pt-0 form-check-dark" type="checkbox" id="selectAll">

                                                    </div>
                                                </th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">System Role</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Last Seen</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>
                                                    <div class="d-flex  align-items-center">
                                                        <div class="the-mail-checkbox pr-4">
                                                            <input class="form-check-input mt-0 pt-0 form-check-dark" type="checkbox" id="formCheck1">
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href="user-details.html">
                                                        <div class=" d-flex align-items-center ">
                                                            <div class="avatar-xs mr-15px ">
                                                                <span class="avatar-title rounded-circle ">
                                                                        D
                                                                    </span>
                                                            </div>
                                                            <div>
                                                                <h5 class="font-size-14 mb-1 "><a href="javascript: void(0); " class="text-dark ">Mark Ellison</a></h5>
                                                                <p class="text-muted mb-0 ">Front end Engineer</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>david@skote.com</td>
                                                <td>
                                                    <a href="tell:254704549859 ">254 704 549 859</a>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-primary font-size-11 m-1 ">Messenger</div>
                                                    </div>
                                                </td>


                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-success font-size-11 m-1 ">Activated</div>
                                                    </div>

                                                </td>
                                                <td>
                                                    9:15 AM
                                                </td>
                                                <td>
                                                    <div class="dropdown">
                                                        <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end ">
                                                            <a class="dropdown-item " href="user-details.html "><i class="font-size-15 mdi mdi-eye-plus-outline me-3 "></i>View Profile</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-tag-remove me-3 "></i>Edit account</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat-plus me-3 "></i>Compose Message</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 dripicons-trash me-3 "></i>Remove Account</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center ">
                                                        <div class="the-mail-checkbox pr-4 ">
                                                            <input class="form-check-input mt-0 pt-0 form-check-dark " type="checkbox " id="formCheck1 ">
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <a href="user-details.html">
                                                        <div class="d-flex align-items-center ">
                                                            <div class="mr-15px ">
                                                                <img class="rounded-circle avatar-xs " src="assets/images/users/avatar-3.jpg " alt=" ">
                                                            </div>
                                                            <div>
                                                                <h5 class="font-size-14 mb-1 "><a href="javascript: void(0); " class="text-dark ">Rafael Morales</a></h5>
                                                            </div>
                                                        </div>
                                                    </a>

                                                </td>

                                                <td>Rafael@skote.com</td>

                                                <td>
                                                    <a href="tell:254704549859 ">254 704 549 859</a>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-primary font-size-11 m-1 ">Messenger</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div href="javascript: void(0); " class="badge badge-soft-danger font-size-11 m-1 ">Inactive</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    3 Jul 2022
                                                </td>

                                                <td>
                                                    <div class="dropdown">
                                                        <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end ">
                                                            <a class="dropdown-item " href="user-details.html "><i class="font-size-15 mdi mdi-eye-plus-outline me-3 "></i>View Profile</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-tag-remove me-3 "></i>Edit account</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat-plus me-3 "></i>Compose Message</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 dripicons-trash me-3 "></i>Remove Account</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center ">
                                                        <div class="the-mail-checkbox pr-4 ">
                                                            <input class="form-check-input mt-0 pt-0 form-check-dark " type="checkbox " id="formCheck1 ">
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <a href="user-details.html">
                                                        <div class="d-flex align-items-center ">
                                                            <div class="avatar-xs mr-15px ">
                                                                <span class="avatar-title rounded-circle ">
                                                                M
                                                            </span>
                                                            </div>
                                                            <div>
                                                                <h5 class="font-size-14 mb-1 "><a href="javascript: void(0); " class="text-dark ">Mark Ellison</a></h5>
                                                                <p class="text-muted mb-0 ">Full Stack Developer</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>mark@skote.com</td>

                                                <td>
                                                    <a href="tell:254704549859 ">254 704 549 859</a>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-secondary font-size-11 m-1 ">Super Admin</div>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-success font-size-11 m-1 ">Activated</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="badge badge-pill badge-soft-success font-size-11 "><i class="mdi mdi-checkbox-blank-circle pr-5 "></i>Online</span>
                                                </td>
                                                <td>
                                                    <div class="dropdown">
                                                        <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end ">
                                                            <a class="dropdown-item " href="user-details.html "><i class="font-size-15 mdi mdi-eye-plus-outline me-3 "></i>View Profile</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-tag-remove me-3 "></i>Edit account</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat-plus me-3 "></i>Compose Message</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 dripicons-trash me-3 "></i>Remove Account</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div class="d-flex align-items-center ">
                                                        <div class="the-mail-checkbox pr-4 ">
                                                            <input class="form-check-input mt-0 pt-0 form-check-dark " type="checkbox " id="formCheck1 ">
                                                        </div>

                                                    </div>
                                                </td>
                                                <td>
                                                    <a href="user-details.html">
                                                        <div class="d-flex align-items-center ">
                                                            <div class="avatar-xs mr-15px ">
                                                                <span class="avatar-title rounded-circle ">
                                                                        J
                                                                    </span>
                                                            </div>
                                                            <div>
                                                                <h5 class="font-size-14 mb-1 "><a href="javascript: void(0); " class="text-dark ">John Santiago</a></h5>
                                                                <p class="text-muted mb-0 ">The manager</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>

                                                <td>john@skote.com</td>

                                                <td>
                                                    <a href="tell:254704549859 ">254 704 549 859</a>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-primary font-size-11 m-1 ">Messenger</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div class="badge badge-soft-success font-size-11 m-1 ">Activated</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span class="badge badge-pill badge-soft-success font-size-11 "><i class="mdi mdi-checkbox-blank-circle pr-5 "></i>Online</span>
                                                </td>
                                                <td>
                                                    <div class="dropdown">
                                                        <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-end ">
                                                            <a class="dropdown-item " href="user-details.html "><i class="font-size-15 mdi mdi-eye-plus-outline me-3 "></i>View Profile</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-tag-remove me-3 "></i>Edit account</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat-plus me-3 "></i>Compose Message</a>
                                                            <a class="dropdown-item " href="# "><i class="font-size-15 dripicons-trash me-3 "></i>Remove Account</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- Transaction Modal -->
            <div class="modal fade transaction-detailModal " tabindex="-1 " role="dialog " aria-labelledby="transaction-detailModalLabel " aria-hidden="true ">
                <div class="modal-dialog modal-dialog-centered " role="document ">
                    <div class="modal-content ">
                        <div class="modal-header ">
                            <h5 class="modal-title " id="transaction-detailModalLabel ">Transactions Details</h5>
                            <button type="button " class="btn-close " data-bs-dismiss="modal " aria-label="Close "></button>
                        </div>
                        <div class="modal-body ">


                            <div class="table-responsive mt-4 ">
                                <table class="table align-middle table-nowrap ">
                                    <tbody>
                                        <tr>
                                            <td style="width: 30% ">
                                                <p class="mb-0 ">Car Plate Number</p>
                                            </td>
                                            <td style="width: 25% ">
                                                <h5 class="mb-0 text-uppercase ">UAG 1235p</h5>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0 ">Vehicle Category</p>
                                            </td>
                                            <td>
                                                <h5 class="mb-0 ">Salon Car</h5>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0 ">Payment Date</p>
                                            </td>
                                            <td>
                                                <h5 class="mb-0 ">22 Apr 2022 06:54PM</h5>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td>
                                                <p class="mb-0 ">San Diego</p>
                                            </td>
                                            <td>
                                                <h5 class="mb-0 ">1,026</h5>
                                            </td>

                                        </tr>
                                        <tr>
                                            <td colspan="2 ">
                                                <p class="mb-0 pb-0 ">Amount Paid</p>
                                                <h3>UGX 2,000</h3>
                                            </td>
                                        </tr>
                                        <TR>
                                            <td col-span="2 ">
                                                <p class="mb-2 ">Attended By<span class="text-primary "> Alex Sebeye</span></p>
                                                <p class="mb-4 ">Toll taker's No. <span class="text-primary ">0704549859</span></p>
                                            </td>
                                        </TR>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer ">
                            <button type="button " class="btn btn-secondary " data-bs-dismiss="modal ">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal -->



            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            © Best Birdy
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>

    </body>


</template>

<script>
    import SideBar from "@/components/SideBar";
    export default {
        name: "userList",
        components: {SideBar}
    }
</script>

<style scoped>

</style>