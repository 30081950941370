<template>


    <!-- By Kelvin Njuguna   13:53:02 GMT -->

    <head>

        <meta charset="utf-8" />
        <title>KENYA | Food Handler County Portal</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta content="Rental management system by Nouveta LTD" name="description" />
        <meta content="Kelvin Njuguna" name="author" />
        <!-- App favicon -->
        <link rel="shortcut icon" href="assets/images/favicon.svg">
        <!-- droping file -->
        <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

        <!-- DataTables -->
        <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
        <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

        <!-- Bootstrap Css -->
        <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
        <!-- Bootstrap select styling -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
        <!-- Icons Css -->
        <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />

        <!-- owl.carousel css -->
        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

        <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">
        <!-- App Css-->
        <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
        <!-- custom Css-->
        <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

    </head>

    <body data-sidebar="dark">



    <!-- Begin page -->
    <div id="layout-wrapper">

        <SideBar/>


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Pending Food handler Certificate applications</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Active Applications</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="card-title text-capitalize mb-0 ">
                                            Applications
                                        </h4>

                                        <div class="d-flex">
                                            <a href="bill-new.html" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                                                <i class="mdi mdi-plus-circle-outline  font-size-16"></i> <span class="pl-1 d-md-inline">Create A Bill</span>
                                            </a>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive ">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="datatable-buttons">
                                            <thead class="table-light">
                                            <tr class="table-dark">
                                                <th scope="col">#</th>
                                                <th scope="col">billNo</th>
                                                <th scope="col">Applicant</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Date</th>
                                                <th scope="col" class="">Application Stage</th>
                                                <th scope="col" class="text-right">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr  v-for="(item, index) in tests" :value="item" :key="index">
                                                <td class="text-capitalize text-nowrap">{{index+1}}.</td>
                                                <td class="text-capitalize text-nowrap">{{item.testDetails.billNo}}</td>
                                                <td class="text-capitalize text-nowrap">{{item.testDetails.applicant}}</td>
                                                <td>{{item.testDetails.phoneNumber}}</td>
                                                <td class="text-nowrap">{{item.testDetails.email}}</td>
                                                <td class="text-capitalize text-nowrap">{{item.testDetails.dateCreated}}<!--<span class="text-muted">10:56 PM</span>--></td>

                                                <td>
                                                    <span class="badge badge-soft-success fw-bolder text-black text-uppercase">{{item.testDetails.applicationStage}}</span>
                                                </td>
                                                <td>
                                                    <div class="d-flex justify-content-end">
                                                        <button @click="viewDetails(item)" type="button" class="btn btn-info btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal"><i class="mdi-star mdi"></i> Issue Certificate</button>
                                                        <div class="dropdown d-none">
                                                            <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                <i class="bx bx-dots-vertical-rounded"></i>
                                                            </a>

                                                            <div class="dropdown-menu dropdown-menu-end ">
                                                                <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Send Email Reminder</a>
                                                                <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send SMS Reminder</a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot class="table-dark">
                                            <tr>
                                                <th colspan="5">
                                                    {{tests.length}} Applications
                                                </th>
                                                <th colspan="3">
                                                    <span class="badge badge-soft-warning fw-bolder text-uppercase">Pending Test</span> {{tests.length}}

                                                    <!--  <span class="badge badge-soft-info fw-bolder text-uppercase ms-2">Pending Approval</span> 30

                                                      <span class="badge badge-soft-success fw-bolder text-uppercase ms-2">Pending Certification</span> 45-->
                                                </th>

                                            </tr>

                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- Test results modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Issue Certificate</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Test Results For:</strong><br>
                                        {{test.testDetails.applicant}}<br>
                                        {{test.testDetails.email}},  {{test.testDetails.phoneNumber}}<br>
                                        ID No.  {{test.testDetails.idNo}}.<br/>
                                        <br>


                                    </address>
                                </div>
                                <!-- <div class="col-6 text-right">
                                     <address>
                                         <strong class="text-capitalize">Location Details</strong><br>
                                         Street/Road: Mombasa Road<br>
                                         Plot No.: 122202/1250<br>
                                         Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                         Subcounty: CBD. Ward: Mututu


                                     </address>
                                 </div>-->
                            </div>

                            <div class="col-12">
                                <div class="hori-timeline mt-4">
                                    <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel" id="timeline-carousel">
                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                                                    <h5 class="mb-4">Application</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted text-capitalize">Self Service Portal</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list active">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">Pending</div>
                                                    <h5 class="mb-4">Lab Test</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted">-</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">Pending</div>
                                                    <h5 class="mb-4">Application Verdict</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted">-</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="item event-list">
                                            <div>
                                                <div class="event-date">
                                                    <div class="text-primary mb-1">Pending ...</div>
                                                    <h5 class="mb-4">Certification</h5>
                                                </div>
                                                <div class="event-down-icon">
                                                    <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                                </div>

                                                <div class="mt-3 px-3">
                                                    <p class="text-muted">-</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">{{test.testDetails.billNo}}</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead class="bg-light">
                                        <tr>
                                            <th style="width: 70px;">#</th>
                                            <th>Test</th>
                                            <th class="" >Lab Results</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item, index) in test.testInfo" :value="item" :key="index">
                                            <td>{{index+1}}</td>
                                            <td>{{item.testName}}</td>
                                            <td>
                                                <span v-if="item.status=='PASS'" class="badge badge-soft-success fw-bolder  text-uppercase">{{item.result}}</span>
                                                <span v-if="item.status=='FAIL'" class="badge badge-soft-danger fw-bolder  text-uppercase">{{item.result}}</span>
                                                <span v-if="item.status=='PENDING'" class="badge badge-soft-info fw-bolder  text-uppercase">{{item.result}}</span>
                                            </td>

                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="alert alert-warning" role="alert">
                                <h5 class="alert-heading">Doctor's Notes</h5>
                                <p>
                                    {{test.testDetails.doctorsNotes}}
                                </p>
                            </div>

                        </div>
                        <div class="modal-footer">

                            <a @click="issueCert()" data-bs-dismiss="modal" type="button" class="btn btn-info waves-effect waves-light w-100">
                                <i class="mdi mdi-file-check font-size-16 align-middle"></i> Process Certificate
                            </a>

                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            ©County
                        </div>
                        <div class="col-sm-6 ">
                            <div class="text-sm-end d-sm-block ">
                                Developed by County
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <!-- end main content-->

    </div>


    </body>


</template>

<script>
    import SideBar from "@/components/SideBar";
    import {execute, health} from "@/api";
    export default {
        name: "ApplicationsPage",
        data(){
            return{
                tests: [],
                test:{
                    testInfo:[],
                    testDetails:{
                        billNo:'',
                        applicant: '',
                        idNo: '',
                        phoneNumber: '',
                        email: '',
                        stageId:'',
                        applicationStage: '',
                        status: '',
                        dateCreated: '',
                        labNotes:'',
                        doctorsNotes:'',
                    },


                }
            }

        },
        components: {SideBar},
        mounted() {
            this.getTests()
        },
        methods:{
            viewDetails(item){
                this.test.testDetails = item.testDetails
                this.test.testInfo = item.testInfo
                this.test.labNotes =item.testDetails.labNotes
            },
            getTests(){
                this.tests = []
                const data = new FormData();
                data.append("function", "getTests");
                data.append("stageId", "3");
                execute(data,health)
                    .then((res) =>{
                        if (res.data.success) {
                            this.tests = res.data.data.tests;
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            issueCert(){
                const data = new FormData();
                data.append("function", "issueCert");
                data.append("billNo", this.test.testDetails.billNo);
                execute(data,health)
                    .then((res) =>{
                        if (res.data.success) {
                            this.printCertificate(this.test.testDetails.billNo)
                            this.getTests()
                        }else{
                            this.message =res.data.message
                        }
                    })
                    .catch((e) => {
                        this.message =e.message
                    });
            },
            printCertificate(billNo){
                localStorage['params'] = JSON.stringify({
                    billNo: billNo
                })
                const routeData = this.$router.resolve({name: 'food-handler-cert'});
                window.open(routeData.href, '_blank');
            }

        }
    }
</script>

<style scoped>

</style>