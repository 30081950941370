<template>

<!-- By Kelvin Njuguna   13:53:02 GMT -->

<head>

    <meta charset="utf-8" />
    <title>KENYA | Food handler county Portal | User registration</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Keep your finances and membership up to date" name="description" />
    <meta content="Kelvin Njuguna Ndungu" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />


</head>

<body data-sidebar="dark">



    <!-- Begin page -->
    <div id="layout-wrapper">


        <SideBar/>


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">User Registrationb</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item active">User registration</li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->

                    <!-- eTransactions table -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card p-4">

                                <div class="card-body">
                                    <h4 class="card-title text-capitalize">User registration</h4>
                                    <p class="pb-4">Fill in the form bellow to register the toll taker into the system. Once registered an <strong>OTP</strong> will be sent to the toll taker of which they are expected to use as their PIn for their very first log in.
                                    </p>
                                    <hr class="mb-5 d-none">
                                    <form action="">
                                        <div class="row form-space">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="">First Name <strong class="text-danger">*</strong></label>
                                                    <input type="text" class="form-control" placeholder="Enter the user's first Name">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="">Other Name(s) <strong class="text-danger">*</strong></label>
                                                    <input type="text" class="form-control" placeholder="Enter the user's other name(s)">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <label for="Gender">Gender</label>
                                                <div class="d-flex mb-3">
                                                    <div class="form-check mb-3 pr-15px">
                                                        <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" checked="">
                                                        <label class="form-check-label" for="formRadios1">
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div class="form-check mb-3">
                                                        <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" checked="">
                                                        <label class="form-check-label" for="formRadios1">
                                                            Female
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="">Email Address <strong class="text-danger">*</strong></label>
                                                    <input type="email" class="form-control" placeholder="Enter the user's email Address">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="">Phone <strong class="text-danger">*</strong></label>
                                                    <input type="text" class="form-control" placeholder="Enter the user's phone number">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="">Account Role <strong class="text-danger">*</strong></label>
                                                    <select class="form-select">
                                                        <option>Select role</option>
                                                        <option>Messenger</option>
                                                        <option>Super Admin</option>
                                                    </select>
                                                    <small>The Messenger account role will only have responsibilities for sending messages only while the super admin account will have <span class="fw-semibold">user management capabilities.</span> plus all other capabilities within the system</small>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="">Company Role</label>
                                                    <input type="text" class="form-control" placeholder="Enter the person's role at the company">
                                                </div>
                                            </div>

                                        </div>



                                        <div class="row">
                                            <div class="col-lg-10">
                                                <button type="submit" class="btn btn-primary"><i class="mdi mdi-account-plus-outline me-1"></i>Register User</button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->




            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                             © Best Birdy
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>

    </body>

</template>

<script>
    import SideBar from "@/components/SideBar";
    export default {
        name: "UseRegistration",
        components: {SideBar}
    }
</script>

<style scoped>

</style>