<template>
    <head>
        <meta charset="utf-8">
        <title class="">Certificate Of Medical Examination </title>
        <!-- <link href="https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Open+Sans:300,400,600,700&display=swap" rel="stylesheet"> -->
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Aldrich|Fira+Sans:200,300,400,500,700,800,900|Norican&display=swap&subset=cyrillic" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,500;0,600;0,700;1,400;1,562;1,600&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@300;600;700;800;900&display=swap" rel="stylesheet">
        <link href="https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap" rel="stylesheet">

        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Fondamento:ital@0;1&display=swap" rel="stylesheet">

        <!-- <link rel="stylesheet" href="css/bootstrap.min.css"> -->

    </head>

    <body id="#">
    <page>
        <div class="position-relative" style="position: relative; padding: 0px; margin: 0px; font-family: 'Montserrat', sans-serif; ;box-sizing: border-box; background-size: contain; padding: 0.2in; background: aliceblue; width: 210mm; height:297mm; background: url('assets/bg-image.jpg'); background-size: contain;"
             id="screenshot">
            <header>

                <div class="the-qr" style="position: absolute; right: 0.2in;">
                    <div class="" style="display: flex; justify-content: center; align-items: center; height: 100px; width: 100%; border: 2px solid #0045a1; margin-bottom: 0.05in; padding:0.05in;"><img src="assets/code-demo.png" style="height: 80px;"></div>
                    <div class="text-right ">
                        <p class="serial mt-2 " style="margin-top: 0px; padding-top: 0px; text-align: right; font-size: 10px !important;">Serial NO. {{foodHandlerCert.id}} </p>
                    </div>
                </div>
                <div style="display: flex; justify-content: center; flex-flow: column; align-items: center; width: 100%; text-align: center; margin-top: 0.2in;">
                    <img style="width: 167px; margin-bottom: 0.2in;" src="../../logo/county_logo_plain.png" />
                    <h1 style="font-family: 'Amiri', serif; margin-bottom: 0px; font-size:40px; font-weight: bold; margin-top: 0px ; padding-top: 0px; padding: 0px !important;">
                        <bold>COUNTY GOVERNMENT OF KENYA</bold>
                    </h1>
                    <h6 style="font-family: 'Amiri', serif; font-size:24px; color: #0045a1; margin: 0px; padding: 0px; font-weight: 400; margin-bottom: 0px; padding-bottom: 0px;">ONE PEOPLE * ONE COUNTY * ONE DESTINY</h6>
                    <div style="background-color: #0045a1;width: 1in;height: 0.05in;"></div>
                </div>

            </header>
            <section class="row pb-5" style="padding-top: 0.5in;">
                <div class="col-12 pt-5 px-4">
                    <div class="cert-heading lora-heading my-3" style="padding-top: 0px !important; margin-top: 0.2in !important;">
                        <img src="../Food-handler/assets/ornament.svg" style="width: 170px;transform: scale(-1);" />
                        <h1 class="" style="font-family: 'Fondamento', cursive !important; font-size: 60px; margin: 0px; padding: 0px; "><strong style=" font-family: 'Fondamento', cursive !important; ">Health Certificate</strong></h1>
                        <h6 class=" " style=" margin-top: 0px; padding-top: 0px; font-size: 18px; font-weight: 400; font-family: 'Amiri', serif; margin-bottom: 0.2in; padding-bottom: 0px;">(Food Handler) Regulations</h6>


                    </div>
                    <!-- main content-->
                    <div style="display: flex; justify-content: center; align-items: center; flex-flow: column;">
                        <p class=" col-12 p-0 spacing-1 line-height-2 mt-4 e " style="text-align: center; margin-bottom: 0.2in; font-size: 12px !important; " id="demo ">This Licence is hereby granted to <span class="content-field
                            ">{{foodHandlerCert.names}}</span> to handle, prepare, pack, store food within <span class="content-field ">COUNTY GOVERNMENT OF KENYA.  </span>:<span class="content-field
                            "></span>Nature of Business <span class="content-field
                            ">Food sectors </span></p>
                        <p class=" mb-3 lora-spac " style="text-align: center; margin-bottom: 0.2in; font-size: 12px !important; ">This license is not transferable to any other person without the permission of the Health Authority endorsed hereon.</p>

                        <p style="text-align: center; margin-bottom: 0.2in; font-size: 12px !important; ">This licence is not transferable to any other premises.</p>

                        <img src="../Food-handler/assets/ornament.svg" style="width: 170px; margin-bottom: 0.2in;" />
                    </div>
                    <!-- examination details-->
                    <div class="row mt-0 spacing-1 ">

                        <div class="col-7 dropleft text-left ">
                            <!-- examination details-->
                            <div class="col-12 dropleft text-left p-0 mb-3 mt-5 " style="font-size: 12px !important;">
                                <p class="text-capitalize mb-0 d-flex align-items-end pb-2 " style="font-size: 12px !important; display: flex; align-items: center; margin-bottom: 0px;padding:0px;"><span>Sign: </span><span><img src="assets/sign.png" style="height: 45px;" class=" "></span></p>
                                <p class="text-capitalize mb-0 "><strong style="font-size: 12px !important; margin-bottom: 0px; padding-bottom: 0px;">jane Doe </strong></p><br>
                                    <strong style="font-size: 12px !important; margin-bottom: 0px; padding-bottom: 0px;">Deputy director health</strong>
                                <p class="text-capitalize " style="font-size: 12px !important; margin-bottom: 0px; padding-bottom: 0px;">Date: {{foodHandlerCert.startDate}}</p>
                            </div>
                        </div>
                    </div>




                </div>


            </section>
            <!-- fotter area-->
            <div class=" " id="# ">
                <footer class="row font-14 ">

                    <div style="position: absolute;bottom: 0.2in;right: 0.2in;left: 0.2in;display: flex;flex-flow: column;">
                        <div>
                            <div style="display: flex; justify-content:space-between; align-items: center; width: 100%; ">
                                <div class="">
                                    <p class="dropleft" style=" font-size: 10px !important; ">NB: This Certificate is valid for {{foodHandlerCert.duration}} Only
                                    </p>
                                </div>
                                <div class=" ">
                                    <p class="dropleft font-9 " style="font-size: 10px !important;"><b>Expiry Date {{foodHandlerCert.endDate}}</b></p>
                                </div>
                            </div>
                        </div>

                        <div class="hr1 col-12 px-4 " style="background: #0045a1;"></div>

                        <div class="col-12 ">
                            <p class="mb-3 " style="font-weight: 300; font-size: 10px !important;">Granting of this certificate does not exempt the above named person from adhering and complying to the current established public health guidelines and restrictions by the Government of Kenya.</p>
                        </div>
                    </div>

                    <div class="col-6 d-none " style="display: none; ">
                        <p class="dropleft font-14 "><b>City Hall</b>, | Po Box 30075-00100 Nairobi, Kenya
                        </p>
                    </div>



                </footer>
            </div>

            <div class="row footer margin spacing-1 mt-5 ">

            </div>



            <div class=" " style="display:flex; flex-flow: column; position: absolute; bottom: 1.5in; height: 50px; display: none; ">
                <p style="font-size: 12px !important;">Powered By</p>
               <!-- <img src="assets/lca " class=" " style="height: 50px; ">-->
            </div>
        </div>
    </page>

    <button style="padding: 7px 15px;
        background: #FF9800;
        border: none;
        position: fixed;
        bottom: 30px;
        left: 30px;
        color: white;
        font-size: 18px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); " onclick="window.print()">Print Certificate

    </button>
    <!-- <a href="javascript:new_screenshot() ">Download pdf</a>-->

    <div class="reportContents "></div>
    <!-- scripts -->

    <!-- scripts -->

    </body>

</template>
<style scoped>
    @charset "utf-8";
    /* CSS Document */
    /*fonts*/

    /*  @font-face {
          font-family: "Libre Baskerville";
          src: url("");
          src: url("fonts/librebaskerville-bold-webfont.woff") format("woff");
      }

      @font-face {
          font-family: "Norican";
          src: url("");
          src: url("fonts/norican-regular-webfont.woff") format("woff");
      }

      @font-face {
          font-family: "Aldrich-down";
          src: url("");
          src: url("fonts/aldrich-regular-webfont.woff") format("woff");
      }*/
    /*fonts*/

    body {
        /* font-family: 'Open Sans', sans-serif; */
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        background: repeating-linear-gradient(45deg, #0145c2, #0145c2 10px, #2196F3 10px, #2196F3 20px);
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        justify-content: center;
    }

    .qr-code {
        height: 70px;
    }

    * {
        box-sizing: border-box;
    }

    .libre-bold {
        font-family: 'Libre Baskerville', serif;
        font-weight: bold;
    }

    .libre-reg {
        font-family: 'Libre Baskerville', serif;
        font-weight: 300;
    }

    .a4-size {
        width: 595px;
        min-height: 990px;
        /* background-attachment: fixed; */
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .qr-container {
        width: 105px;
        height: 105px;
        border: 1px solid black;
    }

    p {
        margin-bottom: 7.5px;
    }

    h4 {
        font-size: 15px;
        font-weight: bold;
    }

    .kenya {
        display: block;
        height: 9px;
        color: #0145c3;
        width: 100%;
        background: #be0000;
        border-bottom: solid 2px white;
        /* margin-bottom: 5px; */
        border-top: 2px solid white;
    }

    .kenya2 {
        width: 100%;
        background: #006818;
        height: 4px;
    }

    .kenya3 {
        width: 100%;
        background: black;
        height: 4px;
    }

    header h3 {
        font-size: 27px;
        font-weight: bold;
        text-transform: uppercase;
        white-space: nowrap;
    }

    header h2 {
        font-size: 24px;
        font-weight: bold;
        font-family: 'Libre Baskerville', serif;
        text-transform: capitalize;
        white-space: nowrap;
    }

    .knh-title h2,
    .knh-title h5 {
        font-family: 'Raleway', sans-serif;
    }

    .knh-title h2 {
        font-weight: 800;
        font-size: 33px;
        color: #0145c3;
    }

    .knh-title h5 {
        ont-size: 23px;
        letter-spacing: 7px;
        color: #00abe4 !important;
    }

    .blue {
        display: block;
        height: 8px;
        color: #0145c3;
        width: 100%;
        background: #0145c3;
        border-bottom: solid 2px #5992bf;
        margin-bottom: 5px;
    }

    header h5,
    h5 {
        font-size: 16px;
        font-weight: bold;
        font-family: 'Libre Baskerville', serif;
        text-transform: capitalize;
        white-space: nowrap;
    }

    .cert-heading {
        text-align: center;
    }

    .duration {
        height: 7.5px;
        padding: 0 7.5px;
        border: 1px solid black;
    }

    .table td {
        padding: 7.5px;
    }

    .green {
        display: block;
        height: 8px;
        color: green;
        width: 100%;
        background: #00421B;
        border-bottom: 2px solid #FFDE00;
        margin-bottom: 5px;
    }

    .note {
        font-size: 8px;
    }

    .nbk {
        height: 50px;
        /* float: right; */
        /* height: 30px; */
        /* position: absolute; */
        right: 10px;
        bottom: -35px;
    }

    .locence-no {
        background: rgba(255, 222, 0, .35);
    }

    h1 {
        font-family: 'Norican', serif;
        font-size: 24px;
    }

    p {
        font-family: 'Fira Sans', sans-serif;
    }

    h2 {
        font-size: 34px;
        font-weight: bold;
        font-family: ro;
    }

    .serial {
        font-family: 'Aldrich-down', sans-serif;
    }

    .hr1 {
        background: black;
        width: 100%;
        height: 1px;
        margin-bottom: 2px;
    }

    .footer {
        font-size: 9px;
        font-weight: 700;
    }

    .hr2 {
        background: black;
        width: 100%;
        height: 4px;
    }

    .other-num {
        left: 15px;
        right: 15px;
        font-size: 12px;
        font-weight: 500;
        top: 8px;
    }

    .content-field {
        font-family: 'Share Tech Mono', monospace;
        font-weight: bold;
        text-decoration: underline;
    }

    footer p {
        font-size: 7px;
    }

    .footer-container {
        bottom: 0px;
        width: 95%;
    }

    .font-10 {
        font-size: 10px;
    }

    .font-9 {
        font-size: 9px;
    }

    .figure-img img {
        width: 100%;
        max-width: 250px;
    }

    @media print {
        .print-btn {
            display: none !important;
        }
        body {
            max-height: 100vh;
            overflow: hidden;
            /*
                    height: 297mm !important;
                    width: 210mm !important
            */
        }
        .lora-heading h1 {
            font-weight: 800 !important;
            font-weight: bold !important;
        }
        .margin {
            margin-top: 40px !important;
        }
        .nbk {
            height: 100px !important;
        }
        .signature {
            height: 78px !important;
        }
        .qr-code {
            height: 140px !important;
        }
        .figure-img img {
            width: 100%;
        }
        .cert-title h2 {
            font-size: 50px;
        }
        .cert-title h5 {
            font-size: 27px;
        }
        .cert-heading h1 {
            font-size: 60px;
        }
        .cert-heading h5 {
            font-size: 40px;
        }
        .knh-title h2 {
            font-weight: 700;
            font-size: 70px !important;
            color: #0145c3;
        }
        .knh-title h5 {
            font-size: 50px;
        }
        .nms-title h2 {
            font-weight: 700;
            font-size: 45px !important;
        }
        .nms-title h5 {
            font-size: 25px !important;
        }
        p {
            font-size: 20px !important;
        }
        .a4-size {
            margin: 5px 7px;
            height: calc(100vh - 19px);
            width: 100%;
            /*		height: 200vh !important;*/
        }
        .stamp img {
            height: 300px;
        }
        .font-9,
        .font-10 {
            font-size: 14px;
        }
        .font-14 {
            font-size: 14px !important;
        }
        .lora-heading h1 {
            font-weight: bold !important;
        }
        .lora-heading h1 {
            font-weight: bold !important;
            font-size: 60px;
        }
        h6 {
            font-size: 20px;
        }
    }

    .lora-space {
        margin-bottom: 50px !important;
    }

    .signature {
        height: 50px;
    }

    .spacing-1 {
        letter-spacing: 1px !important;
    }

    .font-12 {
        font-size: 12px !important;
    }

    .font-14 {
        font-size: 14px;
    }

    .line-height-2 {
        line-height: 2;
    }

    .print-btn {
        padding: 7px 15px;
        background: #FF9800;
        border: none;
        position: fixed;
        bottom: 30px;
        left: 30px;
        color: white;
        font-size: 18px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    }

    .lora-heading {
        margin-top: 150px !important;
    }

    .lora-heading h1,
    .lora-heading h5 {
        font-family: 'Lora', serif !important;
        text-transform: capitalize;
        font-family: Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", "serif" !important;
    }

    .lora-heading h1 {
        font-weight: 600;
    }

    .lora-heading {
        padding-top: 30px !important;
    }

    .stamp img {
        height: 76px;
        transform: rotate(-27deg);
    }

    * {
        box-sizing: border-box;
        /* font-family: 'Montserrat', sans-serif; */
        -webkit-print-color-adjust: exact !important;
        /* Chrome, Safari */
        color-adjust: exact !important;
        /*Firefox*/
    }

    @media print {
        .print-btn {
            display: none;
        }
        body {
            margin: 0px !important;
            padding: 0px !important;
        }
        @page {
            /* size: auto; */
            /* auto is the initial value */
            /* this affects the margin in the printer settings */
            /* width: 210mm;*/
            /* height: 297mm; */
            size: A4 portrait;
            margin: 0px;
            margin-bottom: 0px;
            margin: 0px;
        }
    }

    @page {
        /* size: auto; */
        /* auto is the initial value */
        /* this affects the margin in the printer settings */
        /* width: 210mm;
            height: 297mm; */
        size: A4 portrait;
        margin: 0px;
        margin-bottom: 0px;
        margin: 0px;
    }
</style>


<script>
    import { execute,health } from "@/api";
    export default {
        name: "FoodHandlerCert",
        data() {
            return {
                foodHandlerCert:{
                    id:'',
                    billNo: '',
                    amountPaid: '',
                    names: '',
                    idNo: '',
                    phoneNumber: '',
                    email: '',
                    duration: '',
                    startDate: '',
                    endDate: '',
                    status: '',
                    dateCreated: ''

                }
            };
        },
        mounted() {
            let params = {}
            try {
                params = JSON.parse(localStorage['params'])
                console.log('####'+ params.billNo)
                this.getCertificate(params.billNo);
            } catch (error) {
                // ignore
            }
        },
        methods: {
            getWardName(wardID) {
                const nameMap = {
                    "432471235": "BARUT",
                    "432511254": "BIASHARA",
                    "432461227": "BONDE",
                    "432471231": "ELBURGON",
                    "432521259": "ELEMENTAITA",
                    "432481240": "FLAMINGO",
                    "432491241": "GITHIORO",
                    "432471236": "KAPKURES",
                    "432471234": "KAPTEMBWO",
                    "432461229": "KIAMUNYI",
                    "432481238": "KIHOTO",
                    "432461226": "KIPKELION",
                    "432451225": "LARE",
                    "432491244": "LONDON",
                    "432491243": "MAENDELEO",
                    "432521260": "MAI MAHIU",
                    "432521258": "MAI-ELLA",
                    "432531261": "MENENGAI WEST",
                    "432521257": "MIRERA",

// and so on for the rest of the wards
                };

                return nameMap[wardID] || "Village";
            },
            getName(subCountyID) {
                const nameMap = {
                    "43247": "NAIVASHA",
                    "43251": "RONGAI",
                    "43246": "GILGIL",
                    "43248": "KURESOI NORTH",
                    "43252": "KURESOI SOUTH",
                    "43249": "NAKURU WEST",
                    "43245": "NAKURU EAST",
                    "43253": "MOLO",
                    "43250": "SUBUKIA"
                };
                return nameMap[subCountyID] || "Home Town";
            },
            showDate() {
                const dateObj = new Date();
                const currentDate =
                    dateObj.getDate() +
                    "/" +
                    dateObj.getMonth() +
                    "/" +
                    dateObj.getFullYear();
                return currentDate;
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
            getCertificate(billNo) {
                const data = new FormData();
                data.append("function", "getCertificate");
                data.append("billNo", billNo);
                execute(data, health)
                    .then((res) => {
                        if (res.data.success) {
                            this.foodHandlerCert = res.data.data.foodHandlerCert;
                        } else {
                            alert(res.data.data.message);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                    });
            },
        },
    };

</script>






