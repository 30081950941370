import { createWebHashHistory, createRouter } from "vue-router";

import loging from "../components/LoginPage.vue"
import dashboard from "../components/DashboardPage.vue"
import applications from "../components/Applications.vue"
import testCatalogue from "../components/TestCatalogue.vue"
import clinics from  "../components/Clinics.vue"
import activeCerts from  "../components/ActiveCerts.vue"
import inactiveCerts from  "../components/inactiveCerts.vue"
import receiptedBill from  "../components/ReceiptedBill.vue"
import UnpaidBill from  "../components/UnpaidBill.vue"
import ReportsClinic from  "../components/ReportsClinic.vue"
import ClinicReportsDetails from "../components/ReportsClinicDetails.vue"
import ReportsDoctor from "../components/ReportsDoctor.vue"
import ReportsDoctorDetails from "../components/ReportsDoctorDetails.vue"
import ReportsArchivedApplications from "../components/ReportsArchivedApplications.vue"
import UserList from "../components/UserList.vue"
import UseRegistration from "../components/UseRegistration.vue"
import NewBill from "../components/NewBill.vue"
import Bill from "../../public/Prints/Bill/Bill.vue";
import PrintReceipt from "../../public/Prints/Receipts/PrintReceipt.vue";
import DoctorsApproval from "../components/DoctorsApproval.vue"
import IssueCertificate from "../components/IssueCertificate.vue"
import FoodHandlerCert from "../../public/Prints/Food-handler/FoodHandlerCert.vue";



const routes = [
    {
        path: "/",
        name: "Login-page",
        component: loging,
        meta: {public: true}
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: dashboard,
    }, {
        path: "/applications",
        name: "applications",
        component: applications,
    },
    {
        path: "/test-catalogue",
        name: "test-catalogue",
        component: testCatalogue,
    },{
        path: "/clinics",
        name: "clinics",
        component: clinics,
    },
    {
        path: "/active-certs",
        name: "active-certs",
        component: activeCerts,
    },
    {
        path: "/inactive-certs",
        name: "inactive-certs",
        component: inactiveCerts,
    },{
        path: "/receipted-bills",
        name: "receipted-bills",
        component: receiptedBill,
    },{
        path: "/unpaid-bills",
        name: "unpaid-bills",
        component: UnpaidBill,
    },{
        path: "/reports-clinic",
        name: "reports-clinic",
        component: ReportsClinic,
    },{
        path: "/clinic-reports-details",
        name: "clinic-reports-details",
        component: ClinicReportsDetails,
    },{
        path: "/reports-doctor",
        name: "reports-doctor",
        component: ReportsDoctor,
    },{
        path: "/reports-doctor-details",
        name: "reports-doctor-details",
        component: ReportsDoctorDetails,
    },{
        path: "/reports-archived-applications",
        name: "reports-archived-applications",
        component: ReportsArchivedApplications,
    },{
        path: "/user-list",
        name: "user-list",
        component: UserList,
    },{
        path: "/user-registration",
        name: "user-registration",
        component: UseRegistration,
    },
    {
        path: "/new-bill",
        name: "new-bill",
        component: NewBill,
    },
    {
        path: '/bill',
        name: 'bill',
        component: Bill,
        meta:{public: true}
    },
    {
        path: "/receipt",
        name: "receipt",
        component: PrintReceipt,
        meta: {public: true}
    },{
        path: "/approvals",
        name: "approvals",
        component: DoctorsApproval,
        meta: {public: true}
    },
    {
        path: "/issue-certificate",
        name: "issue-certificate",
        component: IssueCertificate,
        meta: {public: true}
    },{
        path: "/food-handler-cert",
        name: "food-handler-cert",
        component: FoodHandlerCert,
        meta: {public: true}
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;