<template>

    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a @click="gotTo('dashboard')" class="logo logo-dark">
                            <span class="logo-sm">
                                    <img src="../../public/logo/county_logo.png" alt="" height="22">
                                </span>
                        <span class="logo-lg">
                                    <img src="../../public/logo/county_logo.png" alt="" height="17">
                                </span>
                    </a>

                    <a @click="gotTo('dashboard')" class="logo logo-light">
                            <span class="logo-sm">
                                    <img src="../../public/logo/county_logo.png" alt="" height="50">
                                </span>
                        <span class="logo-lg">
                                    <img src="../../public/logo/county_logo.png" alt="" height="45">
                                </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
                <!-- logo small -->
                <div class="phone-logo d-md-none d-sm-flex"> <img src="assets/images/color-log0.svg" alt="" class="img"></div>



                <!-- App Search-->
                <form class="app-search d-none d-lg-block">
                    <div class="position-relative">
                        <input type="text" class="form-control" placeholder="Search...">
                        <span class="bx bx-search-alt"></span>
                    </div>
                </form>


            </div>

            <div class="d-flex">
                <!-- small search -->
                <div class="dropdown d-inline-block d-lg-none ms-2">
                    <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-magnify"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">

                        <form class="p-3">
                            <div class="form-group m-0">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="dropdown d-none d-lg-inline-block ms-1">
                    <button type="button" class="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen">
                        <i class="bx bx-fullscreen"></i>
                    </button>
                </div>

                <div class="dropdown d-inline-block d-none">

                    <!-- notifications, i will come to this later -->
                    <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="bx bx-bell bx-tada"></i>
                        <span class="badge bg-danger rounded-pill">3</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
                        <div class="p-3">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0" key="t-notifications"> Notifications </h6>
                                </div>
                                <div class="col-auto">
                                    <a href="#!" class="small" key="t-view-all"> View All</a>
                                </div>
                            </div>
                        </div>
                        <div data-simplebar style="max-height: 230px;">
                            <a  class="text-reset notification-item">
                                <div class="d-flex">
                                    <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i class="bx bx-cart"></i>
                                                </span>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1" key="t-your-order">Your order is placed</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a  class="text-reset notification-item">
                                <div class="d-flex">
                                    <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1">James Lemire</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-simplified">It will seem like simplified English.</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a  class="text-reset notification-item">
                                <div class="d-flex">
                                    <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-success rounded-circle font-size-16">
                                                    <i class="bx bx-badge-check"></i>
                                                </span>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1" key="t-shipped">Your item is shipped</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a  class="text-reset notification-item">
                                <div class="d-flex">
                                    <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                    <div class="flex-grow-1">
                                        <h6 class="mb-1">Salena Layfield</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1" key="t-occidental">As a skeptical Cambridge friend of mine occidental.</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="p-2 border-top d-grid">
                            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                                <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>
                            </a>
                        </div>
                    </div>
                </div>



                <div class="dropdown d-inline-block">
                    <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                             alt="Header Avatar">
                        <span class="d-none d-xl-inline-block ms-1" key="t-henry">Adroa Balinda</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end">
                        <!-- item-->
                        <a class="dropdown-item" href="myprofile.html"><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">My Profile</span></a>
                        <a class="dropdown-item" href="my-logs.html"><i class="bx bx-time font-size-16 align-middle me-1"></i> <span key="t-profile">My Logs</span></a>
                        <a class="dropdown-item" href="auth-lock-screen.html"><i class="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item text-danger" @click="gotTo('/')"><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></a>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <!-- ========== Left Sidebar Start ========== -->
    <div class="vertical-menu">

        <div data-simplebar class="h-100">

            <!--- Sidemenu -->
            <div id="sidebar-menu">
                <!-- Left Menu Start -->
                <ul class="metismenu list-unstyled text-capitalize" id="side-menu">
                    <li class="menu-title" key="t-pages">Food Handler County Portal. </li>

                    <li class="side-bar-button" >
                        <a @click="gotTo('new-bill')" class="waves-effect btn btn-light btn-rounded text-left write-msg-btn">
                            <i class=""><img src="assets/images/plus-icon.svg" alt=""></i>
                            <span class="text-capitalize">Create A Bill</span>
                        </a>
                    </li>

                    <li>
                        <a @click="gotTo('dashboard')" class="waves-effect">
                            <i class="bx bx-home-circle"></i><span>Dashboard</span>
                        </a>
                    </li>

                    <li>
                        <a @click="gotTo('applications')" class="waves-effect">
                            <i class="mdi mdi-hospital"></i><span>Applications</span>
                            <span class="badge rounded-pill bg-danger float-end">10</span>
                        </a>
                    </li>


                    <li>
                        <a @click="gotTo('approvals')" class="waves-effect">
                            <i class="mdi mdi-doctor"></i><span>Doctor Approvals</span>
                            <span class="badge rounded-pill bg-danger float-end">10</span>
                        </a>
                    </li>


                    <li>
                        <a @click="gotTo('issue-certificate')" class="waves-effect">
                            <i class="mdi mdi-hospital"></i><span>Issue Certificate</span>
                            <span class="badge rounded-pill bg-danger float-end">10</span>
                        </a>
                    </li>

                    <li>
                        <a @click="gotTo('test-catalogue')" class="waves-effect">
                            <i class="mdi mdi-needle"></i><span>Tests Catalogue</span>
                        </a>
                    </li>

                    <li>
                        <a @click="gotTo('clinics')" class="waves-effect">
                            <i class="mdi mdi-hospital-building"></i><span>Certified Clinics</span>
                        </a>
                    </li>


                    <li class="menu-title" key="t-pages">Reports</li>


                    <li class="">
                        <a  class="waves-effect has-arrow">
                            <i class="mdi-certificate mdi"></i>
                            <span>Certificates</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a @click="gotTo('active-certs')">Active Certificates</a></li>
                            <li><a @click="gotTo('inactive-certs')">Inactive Certifcates</a></li>
                        </ul>
                    </li>
                    <li class="">
                        <a class="waves-effect has-arrow">
                            <i class="bx bx-receipt"></i>
                            <span>Bills</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">

                            <li><a @click="gotTo('receipted-bills')">Receipted Bills</a></li>
                            <li><a @click="gotTo('unpaid-bills')">Unpaid Bills</a></li>

                        </ul>
                    </li>

                    <li>
                        <a @click="gotTo('reports-clinic')" class="waves-effect">
                            <i class="mdi mdi-hospital-box-outline"></i><span>Clinic Reports</span>
                        </a>
                    </li>

                    <li>
                        <a @click="gotTo('reports-doctor')" class="waves-effect">
                            <i class="mdi mdi-stethoscope"></i><span>Doctor Reports</span>
                        </a>
                    </li>

                    <li>
                        <a @click="gotTo('reports-archived-applications')" class="waves-effect">
                            <i class="mdi mdi mdi-archive-outline"></i><span>Archived Applications</span>
                        </a>
                    </li>

                    <li class="menu-title" key="t-pages"></li>

                    <li class="d-none">
                        <a  class="waves-effect has-arrow">
                            <i class="bx bx-file"></i>
                            <span>Reports</span>
                        </a>
                        <ul class="sub-menu text-capitalize" aria-expanded="false">
                            <li><a href="reports-conty.html">Revenus By County</a></li>
                            <li class=""><a href="reports-debtors.html">Debtors</a></li>
                            <li class=""><a href="reports-sources.html">Revenue sources</a></li>
                            <li class="text-capitalize"><a href="reports-transactions.html">Transactions</a></li>
                        </ul>
                    </li>

                    <li class="d-none">
                        <a  class="waves-effect has-arrow">
                            <i class="bx bx-slider"></i>
                            <span>Settings & Setups</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a href="finance-act.html">Finance atc</a></li>
                            <li><a href="set-roads.html">Roads</a></li>
                        </ul>
                    </li>


                    <li>
                        <a  class="waves-effect has-arrow">
                            <i class="mdi mdi-monitor-multiple"></i>
                            <span>System Users</span>
                        </a>
                        <ul class="sub-menu" aria-expanded="false">
                            <li><a @click="gotTo('user-list')">System Users</a></li>
                            <li><a @click="gotTo('user-registration')">Add a system user</a></li>
                        </ul>
                    </li>



                </ul>
            </div>
            <!-- Sidebar -->
        </div>
    </div>
    <!-- Left Sidebar End -->
</template>

<script>
    import router from "@/router";

    export default {
        name: "SideBar",
        methods: {
            getWardName(wardID) {
                const nameMap = {
                    "432471235": "BARUT",
                    "432511254": "BIASHARA",
                    "432461227": "BONDE",
                    "432471231": "ELBURGON",
                    "432521259": "ELEMENTAITA",
                    "432481240": "FLAMINGO",
                    "432491241": "GITHIORO",
                    "432471236": "KAPKURES",
                    "432471234": "KAPTEMBWO",
                    "432461229": "KIAMUNYI",
                    "432481238": "KIHOTO",
                    "432461226": "KIPKELION",
                    "432451225": "LARE",
                    "432491244": "LONDON",
                    "432491243": "MAENDELEO",
                    "432521260": "MAI MAHIU",
                    "432521258": "MAI-ELLA",
                    "432531261": "MENENGAI WEST",
                    "432521257": "MIRERA",

// and so on for the rest of the wards
                };

                return nameMap[wardID] || "Village";
            },
            getName(subCountyID) {
                const nameMap = {
                    "43247": "NAIVASHA",
                    "43251": "RONGAI",
                    "43246": "GILGIL",
                    "43248": "KURESOI NORTH",
                    "43252": "KURESOI SOUTH",
                    "43249": "NAKURU WEST",
                    "43245": "NAKURU EAST",
                    "43253": "MOLO",
                    "43250": "SUBUKIA"
                };
                return nameMap[subCountyID] || "Home Town";
            },
            router() {
                return router
            },
            gotTo(route){
                router.push(route)
            }
        }
    }
</script>

<style scoped>

</style>