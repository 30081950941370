<template>

<head>

    <meta charset="utf-8" />
    <title>Food Handler County Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

    <!-- <body data-layout="horizontal" data-topbar="dark"> -->
    <!-- Loader -->
    <div id="preloader">
        <div id="status">
            <div class="spinner-chase">
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
                <div class="chase-dot"></div>
            </div>
        </div>
    </div>
    <!-- Begin page -->

    <!-- Begin page -->
    <div id="layout-wrapper">


        <header id="page-topbar">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box">
                        <a href="index.html" class="logo logo-dark">
                            <span class="logo-sm">
                                    <img src="assets/images/logo.svg" alt="" height="22">
                                </span>
                            <span class="logo-lg">
                                    <img src="assets/images/logo-dark.png" alt="" height="17">
                                </span>
                        </a>

                        <a href="index.html" class="logo logo-light">
                            <span class="logo-sm">
                                    <img src="assets/images/the-logo.png" alt="" height="50">
                                </span>
                            <span class="logo-lg">
                                    <img src="assets/images/logo-text-white.png" alt="" height="45">
                                </span>
                        </a>
                    </div>

                    <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i class="fa fa-fw fa-bars"></i>
                    </button>
                    <!-- logo small -->
                    <div class="phone-logo d-md-none d-sm-flex"> <img src="assets/images/color-log0.svg" alt="" class="img"></div>



                    <!-- App Search-->
                    <form class="app-search d-none d-lg-block">
                        <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Search...">
                            <span class="bx bx-search-alt"></span>
                        </div>
                    </form>


                </div>

                <div class="d-flex">
                    <!-- small search -->
                    <div class="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-magnify"></i>
                            </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">

                            <form class="p-3">
                                <div class="form-group m-0">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="dropdown d-none d-lg-inline-block ms-1">
                        <button type="button" class="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen">
                                <i class="bx bx-fullscreen"></i>
                            </button>
                    </div>

                    <div class="dropdown d-inline-block d-none">

                        <!-- notifications, i will come to this later -->
                        <button type="button" class="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="bx bx-bell bx-tada"></i>
                                <span class="badge bg-danger rounded-pill">3</span>
                            </button>
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
                            <div class="p-3">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="m-0" key="t-notifications"> Notifications </h6>
                                    </div>
                                    <div class="col-auto">
                                        <a href="#!" class="small" key="t-view-all"> View All</a>
                                    </div>
                                </div>
                            </div>
                            <div data-simplebar style="max-height: 230px;">
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="d-flex">
                                        <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i class="bx bx-cart"></i>
                                                </span>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="mb-1" key="t-your-order">Your order is placed</h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                        <div class="flex-grow-1">
                                            <h6 class="mb-1">James Lemire</h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1" key="t-simplified">It will seem like simplified English.</p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="d-flex">
                                        <div class="avatar-xs me-3">
                                            <span class="avatar-title bg-success rounded-circle font-size-16">
                                                    <i class="bx bx-badge-check"></i>
                                                </span>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="mb-1" key="t-shipped">Your item is shipped</h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>

                                <a href="javascript: void(0);" class="text-reset notification-item">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                                        <div class="flex-grow-1">
                                            <h6 class="mb-1">Salena Layfield</h6>
                                            <div class="font-size-12 text-muted">
                                                <p class="mb-1" key="t-occidental">As a skeptical Cambridge friend of mine occidental.</p>
                                                <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="p-2 border-top d-grid">
                                <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                                    <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>
                                </a>
                            </div>
                        </div>
                    </div>



                    <div class="dropdown d-inline-block">
                        <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                                    alt="Header Avatar">
                                <span class="d-none d-xl-inline-block ms-1" key="t-henry">Adroa Balinda</span>
                                <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                            </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <!-- item-->
                            <a class="dropdown-item" href="myprofile.html"><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">My Profile</span></a>
                            <a class="dropdown-item" href="my-logs.html"><i class="bx bx-time font-size-16 align-middle me-1"></i> <span key="t-profile">My Logs</span></a>
                            <a class="dropdown-item" href="auth-lock-screen.html"><i class="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span></a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item text-danger" href="auth-login.html"><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">Logout</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <!-- ========== Left Sidebar Start ========== -->
        <div class="vertical-menu">

            <div data-simplebar class="h-100">

                <!--- Sidemenu -->
                <div id="sidebar-menu">
                    <!-- Left Menu Start -->
                    <ul class="metismenu list-unstyled text-capitalize" id="side-menu">
                        <li class="menu-title" key="t-pages">Food Handler County Portal. </li>

                        <li class="side-bar-button" >
                            <a href="bill-new.html" class="waves-effect btn btn-light btn-rounded text-left write-msg-btn">
                                <i class=""><img src="assets/images/plus-icon.svg" alt=""></i>
                                <span class="text-capitalize">Create A Bill</span>
                            </a>
                        </li>

                        <li>
                            <a href="index.html" class="waves-effect">
                                <i class="bx bx-home-circle"></i><span>Dashboard</span>
                            </a>
                        </li>

                        <li>
                            <a href="applications.html" class="waves-effect">
                                <i class="mdi mdi-fountain-pen-tip"></i><span>Applications</span>
                                <span class="badge rounded-pill bg-danger float-end">10</span>
                            </a>
                        </li>

                        <li>
                            <a href="tests-catalogue.html" class="waves-effect">
                                <i class="mdi mdi-needle"></i><span>Tests Catalogoue</span>
                            </a>
                        </li>

                        <li>
                            <a href="clinics.html" class="waves-effect">
                                <i class="mdi mdi-hospital-building"></i><span>Certified Clinics</span>
                            </a>
                        </li>


                        <li class="menu-title" key="t-pages">Reports</li>


                        <li class="">
                            <a href="javascript: void(0);" class="waves-effect has-arrow">
                                <i class="mdi-certificate mdi"></i>
                                <span>Certificates</span>
                            </a>
                            <ul class="sub-menu" aria-expanded="false">
                                <li><a href="cert-food.html">Active Certificates</a></li>
                                <li><a href="cert-inactive.html">Inactive Certifcates</a></li>
                            </ul>
                        </li>
                        <li class="">
                            <a href="javascript: void(0);" class="waves-effect has-arrow">
                                <i class="bx bx-receipt"></i>
                                <span>Bills</span>
                            </a>
                            <ul class="sub-menu" aria-expanded="false">

                                <li><a href="bills-receipted.html">Receipted Bills</a></li>
                                <li><a href="bills-unpaid.html">Unpaid Bills</a></li>

                            </ul>
                        </li>

                        <li>
                            <a href="reports-clinic.html" class="waves-effect">
                                <i class="mdi mdi-hospital-box-outline"></i><span>Clinic Reports</span>
                            </a>
                        </li>

                        <li>
                            <a href="reports-doctor.html" class="waves-effect">
                                <i class="mdi mdi-stethoscope"></i><span>Doctor Reports</span>
                            </a>
                        </li>

                        <li>
                            <a href="reports-archived-applications.html" class="waves-effect">
                                <i class="mdi mdi mdi-archive-outline"></i><span>Archived Applications</span>
                            </a>
                        </li>

                        <li class="menu-title" key="t-pages"></li>

                        <li class="d-none">
                            <a href="javascript: void(0);" class="waves-effect has-arrow">
                                <i class="bx bx-file"></i>
                                <span>Reports</span>
                            </a>
                            <ul class="sub-menu text-capitalize" aria-expanded="false">
                                <li><a href="reports-conty.html">Revenus By County</a></li>
                                <li class=""><a href="reports-debtors.html">Debtors</a></li>
                                <li class=""><a href="reports-sources.html">Revenue sources</a></li>
                                <li class="text-capitalize"><a href="reports-transactions.html">Transactions</a></li>
                            </ul>
                        </li>

                        <li class="d-none">
                            <a href="javascript: void(0);" class="waves-effect has-arrow">
                                <i class="bx bx-slider"></i>
                                <span>Settings & Setups</span>
                            </a>
                            <ul class="sub-menu" aria-expanded="false">
                                <li><a href="finance-act.html">Finance atc</a></li>
                                <li><a href="set-roads.html">Roads</a></li>
                            </ul>
                        </li>


                        <li>
                            <a href="javascript: void(0);" class="waves-effect has-arrow">
                                <i class="mdi mdi-monitor-multiple"></i>
                                <span>System Users</span>
                            </a>
                            <ul class="sub-menu" aria-expanded="false">
                                <li><a href="user-userlist.html">System Users</a></li>
                                <li><a href="user-registration.html">Add a system user</a></li>
                            </ul>
                        </li>



                    </ul>
                </div>
                <!-- Sidebar -->
            </div>
        </div>
        <!-- Left Sidebar End -->


        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Clinic Reports</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Clinic Reports</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="card-title text-capitalize mb-0 ">
                                            Clinic visitations overview
                                        </h4>

                                        <div class="d-flex">
                                            <select class="form-control selectpicker w-auto  show-tick" data-style="btn btn-primary waves-light waves-effect month-picker" data-live-search="true" title="Select A month">
                                                <option value="" selected>Mar 2022</option>
                                                <option value="">Feb 2022</option>
                                                <option value="">Jan 2022</option>
                                                <option value="">Dec 2021</option>
                                                <option value="">Nov 2021</option>
                                                <option value="">Sep 2021</option>
                                                <option value="">Oct 2021</option>
                                          </select>

                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr class="table-dark">
                                                    <th scope="col">#</th>
                                                    <th scope="col">Clinic</th>
                                                    <th scope="col">Visitations</th>
                                                    <th scope="col">Approved Applications</th>
                                                    <th scope="col">Rejected Applications</th>
                                                    <th scope="col" class="">Pending Applications</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-capitalize text-nowrap">1.</td>
                                                    <td class="text-capitalize">Kenyatta Hospital</td>
                                                    <td class="text-capitalize text-nowrap">23</td>
                                                    <td class="text-capitalize text-nowrap">12</td>
                                                    <td class="text-capitalize text-nowrap">11</td>
                                                    <td class="text-capitalize text-nowrap">0</td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a @click="gotTo('clinic-reports-details')" type="button" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3">View Details</a>

                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-capitalize text-nowrap">2.</td>
                                                    <td class="text-capitalize">Sipital Kuu Medical center</td>
                                                    <td class="text-capitalize text-nowrap">23</td>
                                                    <td class="text-capitalize text-nowrap">12</td>
                                                    <td class="text-capitalize text-nowrap">11</td>
                                                    <td class="text-capitalize text-nowrap">0</td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a type="button" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3" href="clinic-reports-details.html">View Details</a>

                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-capitalize text-nowrap">3.</td>
                                                    <td class="text-capitalize">Ubora Hospital</td>
                                                    <td class="text-capitalize text-nowrap">23</td>
                                                    <td class="text-capitalize text-nowrap">12</td>
                                                    <td class="text-capitalize text-nowrap">11</td>
                                                    <td class="text-capitalize text-nowrap">0</td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a type="button" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3" href="clinic-reports-details.html">View Details</a>

                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-capitalize text-nowrap">4.</td>
                                                    <td class="text-capitalize">Afya Bora Hospital</td>
                                                    <td class="text-capitalize text-nowrap">23</td>
                                                    <td class="text-capitalize text-nowrap">12</td>
                                                    <td class="text-capitalize text-nowrap">11</td>
                                                    <td class="text-capitalize text-nowrap">0</td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a type="button" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3" href="clinic-reports-details.html">View Details</a>

                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="text-capitalize text-nowrap">5.</td>
                                                    <td class="text-capitalize">The county Lab</td>
                                                    <td class="text-capitalize text-nowrap">23</td>
                                                    <td class="text-capitalize text-nowrap">12</td>
                                                    <td class="text-capitalize text-nowrap">11</td>
                                                    <td class="text-capitalize text-nowrap">0</td>
                                                    <td>
                                                        <div class="d-flex justify-content-end">
                                                            <a type="button" class="btn btn-primary btn-sm waves-effect waves-light text-nowrap me-3" href="clinic-reports-details.html">View Details</a>

                                                        </div>
                                                    </td>
                                                </tr>



                                            </tbody>
                                            <tfoot class="table-dark">
                                                <tr>

                                                    <th colspan="2">
                                                        5
                                                    </th>


                                                    <th class="text-nowrap" colspan="">203</th>



                                                    <td class="text-nowrap">
                                                        <span class="fw-semibold ">104</span>
                                                    </td>
                                                    <td class="text-nowrap ">
                                                        <span class="fw-semibold ">40</span>
                                                    </td>
                                                    <td>59</td>
                                                    <td></td>
                                                </tr>

                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>


                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Bill details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Bill Prepared for:</strong><br>
                                        Kelvin Njuguna AT Mombasa Road<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 12345689. Car Plate No. KAB 123P <br/>
                                        <br>
                                        On 1 Mar 2022, 10:20 AM

                                    </address>
                                </div>
                                <div class="col-sm-6 text-sm-end d-none">
                                    <address class="mt-2 mt-sm-0">
                                        <strong>Payment Method</strong><br>
                                        <span class="fw-medium">MPESA</span><br>
                                        Phone No. 0704 549 859<br> <br>
                                        On 1 Mar 2022, 10:20 AM
                                    </address>
                                </div>

                                <div class="col-6 text-right">
                                    <address>
                                        <strong class="text-capitalize">Location Details</strong><br>
                                        Street/Road: Mombasa Road<br>
                                        Plot No.: 122202/1250<br>
                                        Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                        Subcounty: CBD. Ward: Mututu


                                    </address>
                                </div>

                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">ND12368</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap vertical-align-middle">
                                        <thead class="bg-light">
                                            <tr>
                                                <th>More Details</th>
                                                <th class="text-end"></th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial Number</p>
                                                </td>
                                                <td class="">12WER345</td>

                                            </tr>

                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business Owner(s)</p>
                                                </td>
                                                <td class="">John Doe Kamau, James Kuria Kimotho</td>

                                            </tr>

                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Profession/Occupation</p>

                                                </td>
                                                <td class="">Food Processing</td>

                                            </tr>
                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Application Date</p>

                                                </td>
                                                <td class="">5 Jan 2022 10:09 AM</td>

                                            </tr>

                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Approved</p>

                                                </td>
                                                <td class="">11 Jan 2022 09:54 AM</td>

                                            </tr>

                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days To Expiry</p>

                                                </td>
                                                <td class="">134 Days</td>

                                            </tr>

                                            <tr class="table-danger">
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount Charged</p>

                                                </td>
                                                <td class="text-uppercase">KES 1,000</td>

                                            </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Bill/bill.html"  type="button" target="_blank" class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Bill
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Receipt Invoice</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>receipt For:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Member No. 410-123
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong></td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                     </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                           © Best Birdy
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>

    </body>

</template>

<script>
    import router from "@/router";

    export default {
        name: "ReportsClinic",
        methods:{
            //clinic-reports-details
            gotTo(route){
                router.push(route)
            }
        }
    }
</script>

<style scoped>

</style>