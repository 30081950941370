<template>
<head>

    <meta charset="utf-8" />
    <title>Food Handler County Portal</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet" type="text/css" />

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->
    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

</head>

<body data-sidebar="dark">

    <!-- Begin page -->
    <div id="layout-wrapper">

        <SideBar/>
        <!-- ============================================================== -->
        <!-- Start right Content here -->
        <!-- ============================================================== -->
        <div class="main-content">

            <div class="page-content">
                <div class="container-fluid">

                    <!-- start page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 class="mb-sm-0 font-size-18">Receipted Bills</h4>

                                <div class="page-title-right">
                                    <ol class="breadcrumb m-0">
                                        <li class="breadcrumb-item"><a href="index.html">Dashboards</a></li>
                                        <li class="breadcrumb-item"><a href="#">Recepted Bills</a></li>
                                    </ol>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end page title -->
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                                    <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                                        <h4 class="card-title text-capitalize mb-0 ">
                                            All receipts for March 2022
                                        </h4>

                                        <div class="d-flex">
                                            <select class="form-control selectpicker w-auto  show-tick" data-style="btn btn-primary waves-light waves-effect month-picker" data-live-search="true" title="Select A month">
                                                <option value="" selected>Mar 2022</option>
                                                <option value="">Feb 2022</option>
                                                <option value="">Jan 2022</option>
                                                <option value="">Dec 2021</option>
                                                <option value="">Nov 2021</option>
                                                <option value="">Sep 2021</option>
                                                <option value="">Oct 2021</option>
                                          </select>


                                        </div>


                                    </div>
                                    <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                                        <button type="button" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive overflow-visible">

                                        <table class="table align-middle table-hover  contacts-table table-striped " id="datatable-buttons">
                                            <thead class="table-light">
                                                <tr class="table-dark">

                                                    <th scope="col">NO.</th>
                                                    <th scope="col">Offender</th>
                                                    <th scope="col">Bill No.</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col" class="text-nowrap">Served By</th>
                                                    <th scope="col" class="text-nowrap">Payment Method</th>
                                                    <th scope="col" class="text-right text-nowrap">Amount</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>


                                                    <td class="text-capitalize text-nowrap">MCA12358</td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <p class="mb-0"><a href="tenant-details.html" title="View Details">Kelvin Njuguna</a></p>
                                                    </td>

                                                    <td>
                                                        <a href="#" class="fw-semibold">INV1234</a>
                                                    </td>

                                                    <td class="text-capitalize text-nowrap ">
                                                        01 Mar 2022, 10:23 AM
                                                    </td>

                                                    <td class="text-capitalize ">
                                                        Stan Lee
                                                    </td>
                                                    <td class="text-capitalize ">
                                                        <span class="text-uppercase  badge badge-soft-success">mpesa</span>
                                                    </td>
                                                    <td class="text-nowrap text-right ">
                                                        <span class="fw-semibold ">KES 1,000</span>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex">
                                                            <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal">View Receipt</button>
                                                            <div class="dropdown">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Email Applicant</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send as SMS</a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>


                                                    <td class="text-capitalize text-nowrap">MCA12358</td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <p class="mb-0"><a href="tenant-details.html" title="View Details">Kelvin Njuguna</a></p>
                                                    </td>

                                                    <td>
                                                        <a href="#" class="fw-semibold">INV1234</a>
                                                    </td>

                                                    <td class="text-capitalize text-nowrap ">
                                                        01 Mar 2022, 10:23 AM
                                                    </td>

                                                    <td class="text-capitalize ">
                                                        Stan Lee
                                                    </td>
                                                    <td class="text-capitalize ">
                                                        <span class="text-uppercase  badge badge-soft-danger">Cash</span>
                                                    </td>
                                                    <td class="text-nowrap text-right ">
                                                        <span class="fw-semibold ">KES 1,000</span>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex">
                                                            <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal">View Receipt</button>
                                                            <div class="dropdown">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Email Applicant</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send as SMS</a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>


                                                    <td class="text-capitalize text-nowrap">MCA12358</td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <p class="mb-0"><a href="tenant-details.html" title="View Details">Kelvin Njuguna</a></p>
                                                    </td>

                                                    <td>
                                                        <a href="#" class="fw-semibold">INV1234</a>
                                                    </td>

                                                    <td class="text-capitalize text-nowrap ">
                                                        01 Mar 2022, 10:23 AM
                                                    </td>

                                                    <td class="text-capitalize ">
                                                        Stan Lee
                                                    </td>
                                                    <td class="text-capitalize ">
                                                        <span class="text-uppercase  badge badge-soft-success">mpesa</span>
                                                    </td>
                                                    <td class="text-nowrap text-right ">
                                                        <span class="fw-semibold ">KES 1,000</span>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex">
                                                            <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal">View Receipt</button>
                                                            <div class="dropdown">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Email Applicant</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send as SMS</a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>


                                                    <td class="text-capitalize text-nowrap">MCA12358</td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <p class="mb-0"><a href="tenant-details.html" title="View Details">Kelvin Njuguna</a></p>
                                                    </td>

                                                    <td>
                                                        <a href="#" class="fw-semibold">INV1234</a>
                                                    </td>

                                                    <td class="text-capitalize text-nowrap ">
                                                        01 Mar 2022, 10:23 AM
                                                    </td>

                                                    <td class="text-capitalize ">
                                                        Stan Lee
                                                    </td>
                                                    <td class="text-capitalize ">
                                                        <span class="text-uppercase  badge badge-soft-success">Bank</span>
                                                    </td>
                                                    <td class="text-nowrap text-right ">
                                                        <span class="fw-semibold ">KES 1,000</span>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex">
                                                            <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal">View Receipt</button>
                                                            <div class="dropdown">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Email Applicant</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send as SMS</a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>


                                                    <td class="text-capitalize text-nowrap">MCA12358</td>
                                                    <td class="text-capitalize text-nowrap">
                                                        <p class="mb-0"><a href="tenant-details.html" title="View Details">Kelvin Njuguna</a></p>
                                                    </td>

                                                    <td>
                                                        <a href="#" class="fw-semibold">INV1234</a>
                                                    </td>

                                                    <td class="text-capitalize text-nowrap ">
                                                        01 Mar 2022, 10:23 AM
                                                    </td>

                                                    <td class="text-capitalize ">
                                                        Stan Lee
                                                    </td>
                                                    <td class="text-capitalize ">
                                                        <span class="text-uppercase  badge badge-soft-success">mpesa</span>
                                                    </td>
                                                    <td class="text-nowrap text-right ">
                                                        <span class="fw-semibold ">KES 1,000</span>
                                                    </td>

                                                    <td>
                                                        <div class="d-flex">
                                                            <button type="button" class="btn btn-success btn-sm waves-effect waves-light text-nowrap me-3" data-bs-toggle="modal" data-bs-target=".receipting-modal">View Receipt</button>
                                                            <div class="dropdown">
                                                                <a class="text-muted font-size-16" role="button" data-bs-toggle="dropdown" aria-haspopup="true">
                                                                    <i class="bx bx-dots-vertical-rounded"></i>
                                                                </a>

                                                                <div class="dropdown-menu dropdown-menu-end ">
                                                                    <a class="dropdown-item " data-bs-toggle="modal" data-bs-target=".receipting-modal" href="# "><i class="font-size-15 mdi mdi-eye me-3 "></i>View</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-printer me-3 "></i>Print</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-email me-3 "></i>Email Applicant</a>
                                                                    <a class="dropdown-item " href="# "><i class="font-size-15 mdi mdi-chat me-3 "></i>Send as SMS</a>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tfoot class="table-dark">
                                                <tr>

                                                    <th class="text-capitalize text-nowrap" colspan="2">23 receipts</th>


                                                    <td class="text-nowrap text-right " colspan="5">
                                                        <span class="fw-semibold ">TOTAL: KES 1,000</span>
                                                    </td>

                                                    <td>

                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end col -->
                    </div>

                    <!-- end row -->
                </div>
                <!-- container-fluid -->
            </div>
            <!-- End Page-content -->

            <!-- receipting modal -->
            <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Receipt Details</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6">
                                    <address>
                                        <strong class="text-capitalize">Received from:</strong><br>
                                        Kelvin Njuguna<br>
                                        email@mail.com, 0704 549 859<br>
                                        ID No. 423-410.

                                    </address>
                                </div>
                                <div class="col-6 text-right">
                                    <address>
                                        <strong class="text-capitalize">Location Details</strong><br>
                                        Street/Road: Mombasa Road<br>
                                        Plot No.: 122202/1250<br>
                                        Building: The Tower. Floor: 12. Door/Stall No.: 123<br/>
                                        Subcounty: CBD. Ward: Mututu


                                    </address>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Certificate Application Details</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap vertical-align-middle">
                                        <thead class="bg-light">
                                            <tr>
                                                <th>More Details</th>
                                                <th class="text-end"></th>

                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial Number</p>
                                                </td>
                                                <td class="">12WER345</td>

                                            </tr>

                                            <tr title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Business Owner(s)</p>
                                                </td>
                                                <td class="">John Doe Kamau, James Kuria Kimotho</td>

                                            </tr>

                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Profession/Occupation</p>

                                                </td>
                                                <td class="">Food Processing</td>

                                            </tr>
                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Application Date</p>

                                                </td>
                                                <td class="">5 Jan 2022 10:09 AM</td>

                                            </tr>

                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Date Approved</p>

                                                </td>
                                                <td class="">11 Jan 2022 09:54 AM</td>

                                            </tr>

                                            <tr >
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days To Expiry</p>

                                                </td>
                                                <td class="">134 Days</td>

                                            </tr>

                                            <tr class="table-success">
                                                <td>
                                                    <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount Paid</p>

                                                </td>
                                                <td class="text-uppercase">KES 1,000</td>

                                            </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">

                            <a href="PrintDocs/Receipts/receipts.html" type="button" target="_blank" class="btn btn-primary waves-effect waves-light">
                                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print receipt
                            </a>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- end of receipting modal -->

            <!-- invoice details -->
            <div class="modal fade invoice-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="myLargeModalLabel">Receipt Invoice</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-12">
                                <address>
                                    <strong>receipt For:</strong><br>
                                    Kelvin Njuguna<br>
                                    email@mail.com, 0704 549 859<br>
                                    Member No. 410-123
                                    <br><br>
                                    1 Mar 2022, 10:20 AM
                                </address>
                            </div>
                            <div class="col-12">
                                <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Invoice Details ( <span class="text-primary fw-medium">No. 1237</span> )</h3>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-nowrap">
                                        <thead>
                                            <tr>
                                                <th style="width: 70px;">No.</th>
                                                <th>Item</th>
                                                <th class="text-end">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>01</td>
                                                <td>Rent Deposit</td>
                                                <td class="text-end">KES 24,500</td>
                                            </tr>

                                            <tr>
                                                <td>02</td>
                                                <td>Monthly Rent</td>
                                                <td class="text-end">KES 12,000</td>
                                            </tr>

                                            <tr>
                                                <td>03</td>
                                                <td>TAX (18%)</td>
                                                <td class="text-end">KES 250</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Total</td>
                                                <td class="text-end fw-bold">KES 36,750</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="text-end">Paid</td>
                                                <td class="text-end  fw-bold">KES 0.00</td>
                                            </tr>

                                            <tr>
                                                <td colspan="2" class="border-0 text-end">
                                                    <strong>Balance</strong></td>
                                                <td class="border-0 text-end">
                                                    <h5 class="m-0 text-uppercase fw-bold">KES 36,750</h5>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class="col-12 d-none">
                                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                                    <table class="w-100">
                                        <tbody>
                                            <tr data-id="1">
                                                <td>
                                                    <label for="" class="">Payment Method</label>
                                                    <select class="form-control selectpicker w-100 payment-method" data-style="btn-secondary w-100" data-live-search="true" title="Select payment Method">
                                                        <option value="Mpesa">MPESA</option>
                                                        <option value="Cash">CASH</option>
                                                     </select>
                                                </td>
                                                <td class="px-3 ">
                                                    <div class="phone-num d-none">
                                                        <label for="">Phone No.</label>
                                                        <input type="text " class="form-control w-100 d-flex " placeholder="Phone No." spellcheck="false" data-ms-editor="true">
                                                    </div>

                                                </td>
                                                <td class="px-3">
                                                    <label for="">Amount To Be Paid</label>
                                                    <input type="text " class="form-control w-100 d-flex" placeholder="KES" spellcheck="false" data-ms-editor="true">

                                                </td>
                                                <td class="text-right float-right">
                                                    <div class="d-flex flex-column">
                                                        <label class="opacity-0">Something</label>
                                                        <a href="#" class="btn btn-primary w-md waves-effect waves-light">Submit</a>
                                                    </div>
                                                </td>


                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div class="float-end">
                                <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i class="mdi mdi-printer font-16px"></i></a>
                                <a href="javascript: void(0);" class="btn btn-primary w-md waves-effect waves-light">Receive Payment</a>
                            </div>
                        </div>

                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- invoice details -->

            <footer class="footer ">
                <div class="container-fluid ">
                    <div class="row ">
                        <div class="col-sm-6 ">
                            © Best Birdy
    </div>
    <div class="col-sm-6 ">
        <div class="text-sm-end d-sm-block ">
            Best Birdy Billing system.
        </div>
    </div>
    </div>
    </div>
    </footer>
    </div>
    <!-- end main content-->

    </div>
    <!-- END layout-wrapper -->
    </body>

</template>

<script>
    import SideBar from "@/components/SideBar";
    export default {
        name: "ReceiptedBill",
        components: {SideBar}
    }
</script>

<style scoped>

</style>